import { GET_HISTORY_ALL } from "../actions/types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HISTORY_ALL:
      return { ...state, get_history_all: action.payload };

    default:
      return state;
  }
};
