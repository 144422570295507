import icon1 from "../../assets/images/aboutus/aboutIcon1.svg";
import icon2 from "../../assets/images/aboutus/aboutIcon2.svg";
import icon3 from "../../assets/images/aboutus/aboutIcon3.svg";
import icon4 from "../../assets/images/aboutus/aboutIcon4.svg";
import textIcon1 from "../../assets/images/aboutus/aboutText1.svg";
import textIcon2 from "../../assets/images/aboutus/aboutText2.svg";
import textIcon3 from "../../assets/images/aboutus/aboutText3.svg";
import textIcon4 from "../../assets/images/aboutus/aboutText4.svg";

const ABOUT_A = [
  {
    id: 1,
    icon: icon1,
    textIcon: textIcon1,
    desc: "Viverra risus elit nibh facilisi non. Condimentum aliquet congue congue enim.",
  },
  {
    id: 2,
    icon: icon2,
    textIcon: textIcon2,
    desc: "Viverra risus elit nibh facilisi non. Condimentum aliquet congue congue enim.",
  },
  {
    id: 3,
    icon: icon3,
    textIcon: textIcon3,
    desc: "Viverra risus elit nibh facilisi non. Condimentum aliquet congue congue enim.",
  },
  {
    id: 4,
    icon: icon4,
    textIcon: textIcon4,
    desc: "Viverra risus elit nibh facilisi non. Condimentum aliquet congue congue enim.",
  },
];

export { ABOUT_A };
