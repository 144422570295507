// export const endpoint = `http://192.168.99.67:7007/`;
export const endpoint = `https://new2d.mybet123.com/`;
export const prodEndpoint = `https://new2d.mybet123.com/`;
export const endpoint_ws = `wss://new2d.mybet123.com/`;
export const prodEndpoint_ws = `wss://new2d.mybet123.com/`;
// export const endpoint = `https://uatnew2d.mybet123.com/`;
// export const prodEndpoint = `https://uatnew2d.mybet123.com/`;
// export const endpoint_ws = `wss://uatnew2d.mybet123.com/`;
// export const prodEndpoint_ws = `wss://uatnew2d.mybet123.com/`;
export const FB_APP_ID = ``;
export const GOOGLE_MAPS_API_KEY = "AIzaSyBf6yoDk8U3P3LxOEk3eReP_RXSAYASCkk";
// export const PUSHER_API = `eebaff772edd12be3a4c`;
export const PUSHER_API_PROD = `e38d9ef8a84980c466a3`;
export const PUSHER_API = `e38d9ef8a84980c466a3`;
