import React from "react";

import MasterUi from "../master-ui/MasterUi";
import Bitcoin2DLive from "./bitcoin-2d-live/Bitcoin2DLive";
import LiveResult from "./live-result/LiveResult";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const LivePage = (props) => {
  let history = useNavigate();

  const {
    alltrans30,
    alltrans120,
    alltrans300,
    alltran9_30,
    alltran12_01,
    alltran14_00,
    alltran4_30,
    lastTrans30,
    bannerTrans,
    flashUpdate,
    updateB,
  } = props;

  let date1 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:30:00`)
  );
  let date1_1 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:29:59`)
  );

  let date2 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 23:59:59`)
  );

  let date3 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:01:00`)
  );
  let date4 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:30:00`)
  );

  let date5 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 00:00:01`)
  );
  let date6 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:00:52`)
  );
  let date7 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:29:52`)
  );
  let date8 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:00:00`)
  );
  let date9 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:00`)
  );
  let date10 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 23:59:59`)
  );
  let nowDate = moment(new Date());

  // let bannerTrans;
  // if (nowDate > date1 && nowDate < date2) {
  //   bannerTrans = alltran4_30;
  // } else if (nowDate > date3 && nowDate < date8) {
  //   bannerTrans = alltran12_01;
  // } else if (nowDate === date6 || nowDate === date7) {
  //   bannerTrans = lastTrans30;
  // } else {
  //   bannerTrans = alltrans30;
  // }

  //12:01

  // console.log("Updated components");
  let changeTrans1201;
  if (nowDate < date3 && nowDate > date5) {
    changeTrans1201 = alltrans30;
  } else {
    changeTrans1201 = alltran12_01;
  }
  //16:30
  let changeTrans1640;
  if (nowDate > date5 && nowDate < date3) {
    changeTrans1640 = null;
  } else if (nowDate > date3 && nowDate < date1) {
    changeTrans1640 = alltrans30;
  } else {
    changeTrans1640 = alltran4_30;
  }

  return (
    <MasterUi>
      <LiveResult
        alltrans={bannerTrans}
        date8={date8}
        date3={date3}
        date10={date10}
        date1={date1}
        nowDate={nowDate}
        flashUpdate={flashUpdate}
        updateB={updateB}
      />
      <br />

      <center>
        {" "}
        <div className="flex flex-col py-2 md:py-2">
          <center>
            <span className="">
              <span className="font-bold text-2xl text-red-700">
                {" "}
                {moment.utc(alltrans30?.tx_date).local().format("ll")}{" "}
              </span>{" "}
            </span>
          </center>
        </div>
      </center>
      <br />
      <br />
      <Bitcoin2DLive
        alltrans={changeTrans1201}
        date={"12:01 PM"}
        mh={"၁၂"}
        mm={"၁"}
        date5={date5}
        date3={date3}
        date1={date1}
        date4={date4}
        date2={date2}
        date8={date8}
        nowDate={nowDate}
        type="1201PM"
      />

      <br />
      <br />
      <br />

      <Bitcoin2DLive
        alltrans={changeTrans1640}
        date={"4:30 PM"}
        mh={"၄"}
        mm={"၃၀"}
        date5={date5}
        date3={date3}
        date1={date1}
        date4={date4}
        date2={date2}
        date8={date8}
        type="430PM"
        nowDate={nowDate}
      />

      <br />
      <br />
      <br />

      {/* <Bitcoin2DLive
        alltrans={changeTrans930}
        date={"9:30 AM"}
        mh={"၉"}
        mm={"၃၀"}
      /> */}

      <br />
      <br />
      <br />
      {/* <Bitcoin2DLive alltrans={changeTrans1400} date={"2:00 PM"} mh={"၂"} /> */}
      <br />
      <br />
      <br />
    </MasterUi>
  );
};

export default LivePage;
