import React from "react";
import TabsRender from "../../reusable/tabs/TabsRender";
import MasterUi from "../master-ui/MasterUi";

const History = () => {
  return (
    <div>
      <MasterUi>
        <br />
        <br />
        <center>
          <h1 className="text-orange-700 font-bold text-3xl">History</h1>
        </center>

        <br />
        <br />

        <TabsRender />
      </MasterUi>
    </div>
  );
};

export default History;
