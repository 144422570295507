import {
  GET_CONVERT1,
  GET_CONVERT2,
  GET_CONVERT3,
  GET_CONVERT4,
  GET_CONVERT5,
} from "../actions/types";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONVERT1:
      return { ...state, get_convert1_result: action.payload };
    case GET_CONVERT2:
      return { ...state, get_convert2_result: action.payload };
    case GET_CONVERT3:
      return { ...state, get_convert3_result: action.payload };
    case GET_CONVERT4:
      return { ...state, get_convert4_result: action.payload };
    case GET_CONVERT5:
      return { ...state, get_convert5_result: action.payload };

    default:
      return state;
  }
};
