import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { configureStore } from "./store";
import io from "socket.io-client";

import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Home from "./components/home/Home";
import RouteAll from "./routes";
import { endpoint_ws, prodEndpoint_ws } from "./config";
const store = configureStore().store;

const URL =
  process.env.NODE_ENV === "development" ? endpoint_ws : prodEndpoint_ws;
const socket = io(URL, { transports: ["websocket", "polling", "flashsocket"] });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      {/* <ConnectedRouter history={configureStore().history}> */}
      <RouteAll socket={socket} />
      {/* </ConnectedRouter> */}
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
