import React from "react";
import MasterUi from "../master-ui/MasterUi";
import aboutImg from "../../assets/images/aboutus/aboutus.svg";
import bottomHeadImg from "../../assets/images/aboutus/bottomHead.svg";
import { ABOUT_A } from "../../util/data/data";

const About = () => {
  return (
    <MasterUi>
      <div className="h-auto">
        <section class="h-full w-[80%] xl:w-[90%] 2xl:max-w-[50%]  mx-auto flex flex-col  xl:flex xl:flex-row justify-between pt-20 ">
          <img src={aboutImg} alt="" />
          <div className="w-full xl:w-[670px] mt-5 xl:mt-16">
            <p className="text-[38px] font-display">
              <span className="font-bold text-[#EC611B]"> New 2D</span>{" "}
              ဆိုတာဘာလဲ ?
            </p>
            <p className="text-[18px] mt-6 mb-10">
              New 2D သည် Thai Index နံပါတ်ထွက်ရှိမှုအစား Blockchain နည်းပညာ
              အသုံးပြု Cryto ငွေကြေးဖြစ်သည့် Bitcoin ကို အခြေခဲ ထားသော
              နောက်ပိုမို ကောင်းမွန် တိကျသည့် 2D နံပါတ်ထွက်ရှိမှု စနစ်
              ဖြစ်ပါသည်။
            </p>

            <img src={bottomHeadImg} alt="" />
          </div>
        </section>
        <section class="h-full mt-20 mb-40 xl:mt-0 xl:mb-0 2xl:mb-0 w-[80%] xl:w-[90%] 2xl:max-w-[50%]  mx-auto flex flex-col  xl:flex xl:flex-row justify-evenly  gap-y-20 xl:gap-y-0">
          {ABOUT_A.map(({ id, icon, textIcon, desc }) => (
            <div
              className="w-full xl:w-[12.813rem] flex flex-col items-center"
              key={id}
            >
              <img src={icon} alt="" />
              <img src={textIcon} alt="" className="mt-3" />
              {/* <p className="text-center text-[18px] mt-4">{desc}</p> */}
            </div>
          ))}
        </section>
      </div>
    </MasterUi>
  );
};

export default About;
