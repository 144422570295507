import React, { useState, useEffect } from "react";
import "./Bitcoin2DLive.css";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Moment from "moment";
import { BiCopy } from "react-icons/bi";

const Bitcoin2DLive = (props) => {
  const {
    alltrans,
    lasttrans,
    date,
    mh,
    mm,
    date5,
    date3,
    date1,
    nowDate,
    date1_1,
    date4,
    date2,
    date8,
    type,
  } = props;

  const [stable, setStable] = useState(1);
  // console.log("True or FalseLive", nowDate > date1 && nowDate < date2);
  // console.log("ALl 2DLive", alltrans);
  useEffect(() => {
    if (alltrans) {
      // console.log("text30Live", alltrans);
      setTimeout(() => {
        setStable(0);
        // console.log("Stable Live", stable);
      }, 5000);
      setStable(1);
    }
  }, [alltrans]);

  const textlen = alltrans?.hash.length;

  const text1 = alltrans?.hash.slice(0, 5);

  const text2 = alltrans?.hash.slice(textlen - 4, textlen - 1);

  const checkData = alltrans?.checkData
    ? alltrans?.checkData
    : alltrans?.check_data;

  const [showModal, setShowModal] = React.useState(false);
  const [copy, setCopy] = React.useState(false);
  const [copy1, setCopy1] = React.useState(false);
  const [copy2, setCopy2] = React.useState(false);
  const [copy3, setCopy3] = React.useState(false);
  const [copy4, setCopy4] = React.useState(false);
  const [copy5, setCopy5] = React.useState(false);

  let condi430_23_59 = nowDate > date1 && nowDate < date2;
  let condi001_12_01 = nowDate > date5 && nowDate < date3;
  let condi1201_14_00 = nowDate > date3 && nowDate < date8;
  let condi1400_16_30 = nowDate > date8 && nowDate < date1;
  let condi001_1400 = nowDate > date5 && nowDate < date8;

  return (
    <div className="container">
      <div
        className="h-auto md:w-7/12 	px-3 py-3 md:px-4 md:py-3 rounded bg-[#FB9148]	 mx-auto"
        style={{
          boxShadow:
            "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px",
        }}
      >
        <span className="float-right rounded-tl-[20px] rounded-br-[20px] px-4 py-1 -mt-[3.05rem] md:-mt-[3.05rem] -mr-4 text-white bg-[#FB9148]">
          {date}
        </span>

        <div className="flex justify-between " style={{ color: "#000" }}>
          <div className="w-[3.375rem] h-[3.375rem] md:w-[4.375rem] md:h-[4.375rem] bg-white border-2 border-[#BA0202] rounded-full grid  place-items-center ">
            <span
              className={`text-[#BA0202] font-display text-[24px]  xl:text-[30px] font-bold ${
                type === "430PM"
                  ? condi001_12_01
                    ? ""
                    : condi1201_14_00
                    ? stable
                      ? ""
                      : "numAnimate"
                    : condi1400_16_30
                    ? stable
                      ? ""
                      : "numAnimate"
                    : ""
                  : type === "1201PM"
                  ? condi001_12_01
                    ? stable
                      ? ""
                      : "numAnimate"
                    : ""
                  : ""
              }`}
            >
              {type === "1201PM"
                ? alltrans
                  ? alltrans?.odds === 0
                    ? condi001_12_01
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : alltrans?.odds.toString().length === 1
                    ? condi001_12_01
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : condi001_12_01
                    ? "- -"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? alltrans?.odds
                    : "- -"
                  : "- -"
                : type === "430PM"
                ? alltrans
                  ? alltrans?.odds === 0
                    ? condi001_1400 || condi1400_16_30
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : alltrans?.odds.toString().length === 1
                    ? condi001_1400 || condi1400_16_30
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : condi001_1400 || condi1400_16_30
                    ? "- -"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? alltrans?.odds
                    : "- -"
                  : "- -"
                : ""}
            </span>
          </div>

          <div className="flex flex-col mt-2">
            <center>
              <span className=" leading-7	text-white">
                TXD ID <br />{" "}
                <a
                  href={`https://www.blockchain.com/btc/tx/${alltrans?.hash}`}
                  target="_blank"
                  className={`text-[#353535] ${
                    type === "430PM"
                      ? condi001_12_01
                        ? ""
                        : condi1201_14_00
                        ? stable
                          ? ""
                          : "numAnimate"
                        : condi1400_16_30
                        ? stable
                          ? ""
                          : "numAnimate"
                        : ""
                      : type === "1201PM"
                      ? condi001_12_01
                        ? stable
                          ? ""
                          : "numAnimate"
                        : ""
                      : ""
                  }`}
                  rel="noreferrer"
                >{`${
                  text1 === undefined
                    ? "*****"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? text1
                    : "*****"
                }***${
                  text2 === undefined
                    ? "*****"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? text2
                    : "*****"
                }`}</a>
              </span>
            </center>
          </div>

          <div className="flex flex-col mt-2">
            <center>
              <span className=" leading-7	text-white">
                Value <br />{" "}
                <span
                  className={`text-[#353535] ${
                    type === "430PM"
                      ? condi001_12_01
                        ? ""
                        : condi1201_14_00
                        ? stable
                          ? ""
                          : "numAnimate"
                        : condi1400_16_30
                        ? stable
                          ? ""
                          : "numAnimate"
                        : ""
                      : type === "1201PM"
                      ? condi001_12_01
                        ? stable
                          ? ""
                          : "numAnimate"
                        : ""
                      : ""
                  }`}
                >
                  {" "}
                  {alltrans?.value
                    ? Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                      ? alltrans?.value
                      : " - - - - - -"
                    : lasttrans?.result?.value
                    ? Moment(lasttrans?.result?.tx_date).format(
                        "YYYY-MM-DD"
                      ) === Moment().format("YYYY-MM-DD")
                      ? lasttrans?.result?.value
                      : " - - - - - -"
                    : " - - - - - -"}{" "}
                </span>
              </span>
            </center>
          </div>

          <div className="flex flex-col mt-4">
            <center>
              <button
                onClick={() => setShowModal(true)}
                className="bg-white   md:btn-md lg:btn-md  px-2 md:px-4 rounded-full text-[#FB9148] hover:text-[#FB9148]"
                style={{ border: "none", outline: "none" }}
              >
                Check
              </button>
            </center>

            {showModal ? (
              <>
                <div className=" w-screen  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-3xl md:max-w-6xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                      <div className="flex items-center justify-end  border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(false)}
                        >
                          <AiOutlineCloseCircle
                            style={{
                              fontSize: "2rem",
                              cursor: "pointer",
                            }}
                          />
                        </button>
                      </div>
                      <div className="relative py-3 flex-auto">
                        <div class="w-10/12 md:w-12/12  lg:12/12 mx-auto relative ">
                          <div class="border-l-2">
                            <div class="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-[#82B4BB] text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                              <div class="w-5 h-5 bg-[#82B4BB] absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                              <div class="w-10 h-1 bg-[#82B4BB] absolute -left-10 z-0"></div>

                              <div class="w-full break-words flex-auto ">
                                <h1 class="text-lg">
                                  အဆင့်(၁) Bitcoin Transaction Hash Code အား
                                  64bit code ပြောင်းလဲခြင်း
                                </h1>
                                <h1 class="text-xl font-bold">
                                  အောက်ပါဖော်ပြ ထားတာသော Bitcoin များသည် {mh}{" "}
                                  နာရီ
                                  {mm ? `${mm}မိနစ်` : ""} အချိန်မှာ ဖြစ်သွားတဲ့
                                  လုပ်ငန်းစဉ် နံပါတ်တွေပါ။SHA256 Asymmetric
                                  Encryption Algorithm နဲ့ ထုတ်လိုက်တဲ့ Hash
                                  Code တွေဖြစ်လို့ ဘယ်သူမှကြိုတင်မသိနိုင်ပါ
                                </h1>
                                <br />
                                <div className="md:flex ">
                                  <h3 className="mt-2">
                                    {checkData?.transaction
                                      ? checkData?.transaction
                                      : lasttrans?.result?.check_data
                                          ?.transaction}
                                  </h3>
                                  <CopyToClipboard
                                    text={
                                      checkData?.transaction
                                        ? checkData?.transaction
                                        : lasttrans?.result?.check_data
                                            ?.transaction
                                    }
                                    onCopy={() => {
                                      setCopy(true);
                                      setTimeout(() => {
                                        setCopy(false);
                                      }, 1000);
                                    }}
                                  >
                                    <button class="ml-2  focus:outline-none border-none  text-white bg-[#164e63] hover:bg-[#164e63] font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center  ">
                                      <BiCopy />
                                      Copy
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                {copy ? (
                                  <center>
                                    <span className="text-white text-center">
                                      Copied!
                                    </span>
                                  </center>
                                ) : null}
                              </div>
                            </div>

                            <div class="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-[#267778] text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                              <div class="w-5 h-5 bg-[#267778] absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                              <div class="w-10 h-1 bg-[#267778] absolute -left-10 z-0"></div>

                              <div class="w-full break-words flex-auto">
                                <h1 class="text-lg">
                                  အဆင့် (၂) HASH SHA256 encryption
                                  ပြောင်းလဲခြင်း
                                </h1>
                                <h1 class="text-xl font-bold">
                                  အဆင့်(၁) မှာရရှိလာတဲ့ code နံပါတ်တွေကို 64bit
                                  hash တန်ဖိုးတွေဖြစ်အောင်
                                  ထပ်တွက်ထုတ်လိုက်ပါတယ်။
                                </h1>
                                <br />

                                <div className="md:flex ">
                                  <h3 className="mt-2">
                                    {checkData?.hex
                                      ? checkData?.hex
                                      : lasttrans?.result?.check_data?.hex}{" "}
                                  </h3>
                                  <CopyToClipboard
                                    text={
                                      checkData?.hex
                                        ? checkData?.hex
                                        : lasttrans?.result?.check_data?.hex
                                    }
                                    onCopy={() => {
                                      setCopy1(true);
                                      setTimeout(() => {
                                        setCopy1(false);
                                      }, 1000);
                                    }}
                                  >
                                    <button class="ml-2  focus:outline-none border-none  text-white bg-[#164e63] hover:bg-[#164e63]  font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center ">
                                      <BiCopy />
                                      Copy
                                    </button>
                                  </CopyToClipboard>
                                </div>

                                {copy1 ? (
                                  <center>
                                    <span className="text-white text-center">
                                      Copied!
                                    </span>
                                  </center>
                                ) : null}
                              </div>
                            </div>

                            <div class="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-[#255E79] text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                              <div class="w-5 h-5 bg-[#255E79] absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                              <div class="w-10 h-1 bg-[#255E79] absolute -left-10 z-0"></div>

                              <div class="w-full break-words flex-auto">
                                <h1 class="text-lg">
                                  အဆင့် (၃) ကိန်းဂဏာန်းပြောင်းလဲခြင်း
                                </h1>
                                <h1 class="text-xl font-bold">
                                  အဆင့်(၂) မှာရရှိလာတဲ့ 64bit hash တန်ဖိုး ရဲ့
                                  ပထမဆုံး 16 လုံး တန်ဖိုးကိုယူပါသည်။
                                </h1>
                                <br />

                                <div className="md:flex ">
                                  <h3 className="mt-2">
                                    {checkData?.slicedHex
                                      ? checkData?.slicedHex
                                      : lasttrans?.result?.check_data
                                          ?.slicedHex}
                                  </h3>
                                  <CopyToClipboard
                                    text={
                                      checkData?.slicedHex
                                        ? checkData?.slicedHex
                                        : lasttrans?.result?.check_data
                                            ?.slicedHex
                                    }
                                    onCopy={() => {
                                      setCopy2(true);
                                      setTimeout(() => {
                                        setCopy2(false);
                                      }, 1000);
                                    }}
                                  >
                                    <button class="ml-2  focus:outline-none border-none  text-white bg-[#164e63] hover:bg-[#164e63]   font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center ">
                                      <BiCopy />
                                      Copy
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                {copy2 ? (
                                  <center>
                                    <span className="text-white text-center">
                                      Copied!
                                    </span>
                                  </center>
                                ) : null}
                              </div>
                            </div>

                            <div class="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-[#EABA3E] text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                              <div class="w-5 h-5 bg-[#EABA3E] absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                              <div class="w-10 h-1 bg-[#EABA3E] absolute -left-10 z-0"></div>

                              <div class="w-full break-words flex-auto">
                                <h1 class="text-lg">
                                  အဆင့် (၄) ကိန်းဂဏာန်းပြောင်းလဲခြင်း
                                </h1>
                                <h1 class="text-xl font-bold">
                                  အဆင့်(၃) မှာရရှိလာသောနံပါတ်များကို Hexadecimal
                                  to Decimal ပြောင်းလဲခြင်း Formula
                                  နဲ့နံပါတ်တွေအဖြစ်ပြောင်းလဲပါတယ်
                                </h1>
                                <br />

                                <div className="md:flex ">
                                  <h3 className="mt-2">
                                    {checkData?.decimalHex
                                      ? checkData?.decimalHex
                                      : lasttrans?.result?.check_data
                                          ?.decimalHex}
                                  </h3>
                                  <CopyToClipboard
                                    text={
                                      checkData?.decimalHex
                                        ? checkData?.decimalHex
                                        : lasttrans?.result?.check_data
                                            ?.decimalHex
                                    }
                                    onCopy={() => {
                                      setCopy3(true);
                                      setTimeout(() => {
                                        setCopy3(false);
                                      }, 1000);
                                    }}
                                  >
                                    <button class="ml-2  focus:outline-none border-none  text-white bg-[#164e63] hover:bg-[#164e63]   font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center ">
                                      <BiCopy />
                                      Copy
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                {copy3 ? (
                                  <center>
                                    <span className="text-white text-center">
                                      Copied!
                                    </span>
                                  </center>
                                ) : null}
                              </div>
                            </div>

                            <div class="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-[#DF473C] text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                              <div class="w-5 h-5 bg-[#DF473C] absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                              <div class="w-10 h-1 bg-[#DF473C] absolute -left-10 z-0"></div>

                              <div class="w-full break-words flex-auto">
                                <h1 class="text-lg">
                                  အဆင့် (၅) ကိန်းဂဏာန်းပြောင်းလဲခြင်း
                                </h1>
                                <h1 class="text-xl font-bold">
                                  အဆင့်(၄) မှာရရှိလာတဲ့ Decimal နံပါတ်တွေ ကို
                                  ပိုင် (Pi) တန်ဖိုး (3.141592653589793) ရဲ့ အဆ
                                  40 နဲ့ စားပြီး ဒဿမ ဂဏန်း တွေအဖြစ်
                                  ထပ်ပြောင်းလဲပါတယ်
                                </h1>
                                <br />

                                <div className="md:flex ">
                                  <h3 className="mt-2">
                                    {checkData?.calVal
                                      ? checkData?.calVal
                                      : lasttrans?.result?.check_data?.calVal}
                                  </h3>
                                  <CopyToClipboard
                                    text={
                                      checkData?.calVal
                                        ? checkData?.calVal
                                        : lasttrans?.result?.check_data?.calVal
                                    }
                                    onCopy={() => {
                                      setCopy4(true);
                                      setTimeout(() => {
                                        setCopy4(false);
                                      }, 1000);
                                    }}
                                  >
                                    <button class="ml-2  focus:outline-none border-none  text-white bg-[#164e63] hover:bg-[#164e63]   font-medium rounded-lg text-sm px-3 py-1.5 text-center inline-flex items-center ">
                                      <BiCopy />
                                      Copy
                                    </button>
                                  </CopyToClipboard>
                                </div>
                                {copy4 ? (
                                  <center>
                                    <span className="text-white text-center">
                                      Copied!
                                    </span>
                                  </center>
                                ) : null}
                              </div>
                            </div>

                            <div class="transform transition cursor-pointer hover:-translate-y-2 ml-10 relative flex items-center px-6 py-4 bg-[#AE3C60] text-white rounded mb-10 flex-col md:flex-row space-y-4 md:space-y-0">
                              <div class="w-5 h-5 bg-[#AE3C60] absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>

                              <div class="w-10 h-1 bg-[#AE3C60] absolute -left-10 z-0"></div>

                              <div class="w-full break-words flex-auto">
                                <h1 class="text-lg">ပေါက်ဂဏန်း:</h1>

                                <br />

                                <h3>
                                  {checkData?.odds.toString().length === 1
                                    ? `0${alltrans?.odds}`
                                    : checkData?.odds
                                    ? checkData?.odds
                                    : "??"}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center justify-end py-2 pr-2 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          type="button"
                          onClick={() => setShowModal(false)}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="opacity-25 fixed inset-0 z-40 bg-black "></div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bitcoin2DLive;
