import {
  GET_LAST_30,
  GET_LAST_120,
  GET_LAST_300,
  GET_TRANS_ALL,
} from "../actions/types";

const initialState = {
  // hash: "89d45faeacd48ea01ddf884f1c978d520b726e599173a497b77024495861afd3",
  // timestamp: 1642661309,
  // tx_date: "Jan 20, 2022 1:18 PM",
  // odds: "14",
  // value: "0637214",
  // checkData: {
  //   transaction:
  //     "89d45faeacd48ea01ddf884f1c978d520b726e599173a497b77024495861afd3",
  //   hex: "4403aed4e653992582829c9fba896a3712efd239cc5f0db4b58342de66774aa1",
  //   slicedHex: "4403aed4e6539925",
  //   decimalHex: 4900953048929769000,
  //   calVal: 0.06372144772481915,
  //   odds: "14",
  // },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRANS_ALL:
      return { ...state, get_trans_all: action.payload };
    case GET_LAST_30:
      return { ...state, get_last_30: action.payload.result };
    case GET_LAST_120:
      return { ...state, get_last_120: action.payload.result };
    case GET_LAST_300:
      return { ...state, get_last_300: action.payload.result };

    default:
      return state;
  }
};
