export const GET_TRANS_ALL = "GET_TRANS_ALL";
export const GET_HISTORY_ALL = "GET_HISTORY_ALL";

export const GET_LAST_30 = "GET_LAST_30";
export const GET_LAST_120 = "GET_LAST_120";
export const GET_LAST_300 = "GET_LAST_300";

export const GET_CONVERT1 = "GET_CONVERT1";
export const GET_CONVERT2 = "GET_CONVERT2";
export const GET_CONVERT3 = "GET_CONVERT3";
export const GET_CONVERT4 = "GET_CONVERT4";
export const GET_CONVERT5 = "GET_CONVERT5";
