import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import logo from "../../../assets/logo/logo.svg";
import download from "../../../assets/images/header/Download.svg";
import { FiX } from "react-icons/fi";

function NavBar() {
  let location = useLocation();
  // console.log("location", location.pathname === "/" && "true");
  const [isOpen, setIsOpen] = useState(false);

  const linkArray = [
    {
      id: 1,
      link_name: "HOME",
      link: "/",
    },
    {
      id: 2,
      link_name: "LIVE",
      link: "/live",
    },
    {
      id: 3,
      link_name: "RESULTS",
      link: "/result",
    },
    {
      id: 4,
      link_name: "PROOFS",
      link: "/proof",
    },
    {
      id: 5,
      link_name: "ABOUT US",
      link: "/about",
    },
    {
      id: 5,
      link_name: "Download",
      link: "/download",
    },
  ];
  return (
    <div>
      {isOpen && (
        <div
          className="xl:hidden fixed top-0 w-screen h-screen bg-[#EC611B] text-white flex flex-col justify-center items-center z-50"
          id="mobile-menu"
        >
          <div
            className="absolute right-10 top-10 w-10 h-10 rounded-full grid place-items-center bg-white text-[#EC611B]"
            onClick={() => setIsOpen(!isOpen)}
          >
            <FiX />
          </div>
          <div className="text-[36px] flex flex-col gap-y-10">
            {linkArray.map(({ id, link_name, link }) => (
              <Link
                to={link}
                className={`hover:bg-orange-700 text-center   rounded-md  font-medium ${
                  link_name === "Download" ? "xl:hidden" : "block"
                }`}
              >
                {link_name}
              </Link>
            ))}
          </div>
        </div>
      )}
      <div className="w-full xl:w-[83rem] mx-auto flex justify-between items-center ">
        <Link to="/">
          <img
            src={logo}
            className="object-cover  md:w-4/12	w-3/12	"
            alt="logo"
          />
        </Link>
        <ul className="hidden ml-10 xl:flex  list-none gap-x-20  font-display ">
          {linkArray.map(({ id, link_name, link }) => (
            <li
              key={id}
              className={` relative ${
                location.pathname === link
                  ? "before:absolute before:-bottom-1 before:w-full before:rounded-full before:h-[2px] before:bg-[#BA0202]  before:transition-all   text-[#BA0202]"
                  : "before:absolute before:-bottom-1 before:w-0 before:rounded-full before:h-[2px] before:bg-[#BA0202]"
              } ${link_name === "Download" && "xl:hidden"}`}
            >
              <Link to={link} className="hover:text-[#BA0202]">
                {link_name}{" "}
              </Link>
            </li>
          ))}
        </ul>
        <Link to="/download">
          <img
            src={download}
            alt=""
            className="cursor-pointer hidden xl:block"
          />
        </Link>
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className=" xl:hidden bg-orange-700 inline-flex items-center justify-center p-2 rounded-full  text-white hover:text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-orange-700 focus:ring-white mr-2 md:mr-0"
          aria-controls="mobile-menu"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="block h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default NavBar;
