import React, { useEffect, useState } from "react";

import Banner from "./banner/Banner";
import Bitcoin2D from "./bitcoin-2d/Bitcoin2D";
import MasterUi from "../master-ui/MasterUi";

import moment from "moment";
import { setMinutes } from "date-fns";
// import { Alltrans } from "../../actions/trans1Action";

function Home(props) {
  const {
    bannerTrans,

    alltrans30,
    alltrans300,
    alltran9_30,
    alltran12_01,
    alltran14_00,
    alltran4_30,
    updateB,
    flashUpdate,
  } = props;

  //930
  const [secondsR930, setSecondsR930] = useState(0);
  const [minutesR930, setMinutesR930] = useState(0);
  const [hoursR930, setHoursR930] = useState(0);
  //1201
  const [secondsR1201, setSecondsR1201] = useState(0);
  const [minutesR1201, setMinutesR1201] = useState(0);
  const [hoursR1201, setHoursR1201] = useState(0);

  //930
  const [secondsR1400, setSecondsR1400] = useState(0);
  const [minutesR1400, setMinutesR1400] = useState(0);
  const [hoursR1400, setHoursR1400] = useState(0);

  //1630
  const [secondsR430, setSecondsR430] = useState(0);
  const [minutesR430, setMinutesR430] = useState(0);
  const [hoursR430, setHoursR430] = useState(0);

  //5
  const [secondsR300, setSecondsR300] = useState(0);
  const [minutesR300, setMinutesR300] = useState(0);
  const [hoursR300, setHoursR300] = useState(0);

  //5
  const [secondsR30, setSecondsR30] = useState(0);
  const [minutesR30, setMinutesR30] = useState(0);
  const [hoursR30, setHoursR30] = useState(0);

  useEffect(() => {
    //25s

    const txt_date30 = moment(moment(alltrans30?.tx_date)).format("MM/DD/YYYY");
    let txt_date930;
    if (nowDate < date5) {
      txt_date930 = moment(moment(alltran9_30?.tx_date).add(1, "days")).format(
        "MM/DD/YYYY"
      );
    } else if (nowDate > date5 && nowDate < date8) {
      txt_date930 = moment(moment(alltran9_30?.tx_date)).format("MM/DD/YYYY");
    } else if (nowDate > date8) {
      txt_date930 = moment(moment(alltran9_30?.tx_date).add(1, "days")).format(
        "MM/DD/YYYY"
      );
    }

    // console.log("True or False 930", alltran9_30?.tx_date);

    //12_01

    let txt_date12;
    if (nowDate < date5) {
      txt_date12 = moment(moment(alltran12_01?.tx_date).add(1, "days")).format(
        "MM/DD/YYYY"
      );
    } else if (nowDate > date5 && nowDate < date3) {
      txt_date12 = moment(moment(alltran12_01?.tx_date)).format("MM/DD/YYYY");
    } else if (nowDate > date3) {
      txt_date12 = moment(moment(alltran12_01?.tx_date).add(1, "days")).format(
        "MM/DD/YYYY"
      );
    }
    // console.log("True or False txt_date12", nowDate < date5);
    // console.log(
    //   "Date1201",

    //   nowDate < date5
    // );
    //2_00

    let txt_date1400;
    if (nowDate < date5) {
      txt_date1400 = moment(
        moment(alltran14_00?.tx_date).add(1, "days")
      ).format("MM/DD/YYYY");
    } else if (nowDate > date5 && nowDate < date10) {
      txt_date1400 = moment(moment(alltran14_00?.tx_date)).format("MM/DD/YYYY");
    } else if (nowDate > date10) {
      txt_date1400 = moment(
        moment(alltran14_00?.tx_date).add(1, "days")
      ).format("MM/DD/YYYY");
    } else {
      txt_date1400 = moment(moment(alltran14_00?.tx_date)).format("MM/DD/YYYY");
    }

    // console.log("True or False 1400", nowDate < date10);

    //4_30
    let txt_date430;
    if (nowDate < date5) {
      txt_date430 = moment(moment(alltran4_30?.tx_date).add(1, "days")).format(
        "MM/DD/YYYY"
      );
    } else if (nowDate > date5 && nowDate < date1) {
      txt_date430 = moment(moment(alltran4_30?.tx_date)).format("MM/DD/YYYY");
    } else if (nowDate > date1) {
      txt_date430 = moment(moment(alltran4_30?.tx_date).add(1, "days")).format(
        "MM/DD/YYYY"
      );
    } else {
      txt_date430 = moment(moment(alltran4_30?.tx_date)).format("MM/DD/YYYY");
    }
    // console.log(
    //   "True or False 1630",
    //   moment(moment(alltran4_30?.tx_date)).format("MM/DD/YYYY")
    // );

    // 9_30
    const interval930 = setInterval(() => {
      let then = moment(new Date(`${txt_date930} 9:30:00`));

      const now = moment(new Date());

      const hours1 = then.diff(now, "hours");
      const minutes1 = then.diff(now, "minutes");
      const seconds1 = then.diff(now, "seconds");

      setHoursR930(hours1);
      setMinutesR930(minutes1 - Number(hours1) * 60);
      setSecondsR930(seconds1 - Number(minutes1) * 60);
    }, 1000);

    //12_01

    const interval1201 = setInterval(() => {
      let then = moment(new Date(`${txt_date12} 12:01`));

      const now = moment(new Date());

      // if (now.valueOf() > then.valueOf()) {
      //   then = moment(moment(new Date(`${txt_date12} 12:01`))).add(5, "hours");
      // }
      const hours1 = then.diff(now, "hours");
      const minutes1 = then.diff(now, "minutes");
      const seconds1 = then.diff(now, "seconds");

      setHoursR1201(
        // now.valueOf() < then.valueOf()
        //   ? Math.abs(hours1 - 24)
        //   : now.valueOf() > then.valueOf()
        //   ? Math.abs(24 - hours1)
        //     :
        hours1
      );
      setMinutesR1201(minutes1 - Number(hours1) * 60);
      setSecondsR1201(seconds1 - Number(minutes1) * 60);
    }, 1000);

    // 9_30
    const interval1400 = setInterval(() => {
      let then = moment(new Date(`${txt_date1400} 14:00`));

      const now = moment(new Date());

      const hours1 = then.diff(now, "hours");
      const minutes1 = then.diff(now, "minutes");
      const seconds1 = then.diff(now, "seconds");

      setHoursR1400(hours1);
      setMinutesR1400(minutes1 - Number(hours1) * 60);
      setSecondsR1400(seconds1 - Number(minutes1) * 60);
    }, 1000);
    // 4_30
    const interval430 = setInterval(() => {
      let then = moment(new Date(`${txt_date430} 16:30`));
      // console.log("Text_Date4_30", then);

      const now = moment(new Date());

      const hours1 = then.diff(now, "hours");
      const minutes1 = then.diff(now, "minutes");
      const seconds1 = then.diff(now, "seconds");

      setHoursR430(
        // now.valueOf() < then.valueOf()
        //   ? Math.abs(hours1 - 24)
        //   : Math.abs(now.valueOf() > then.valueOf())
        //   ? Math.abs(24 - hours1)
        //     :
        Math.abs(hours1)
      );
      setMinutesR430(minutes1 - Number(hours1) * 60);
      setSecondsR430(seconds1 - Number(minutes1) * 60);
    }, 1000);

    // 300

    // const interval300 = setInterval(() => {
    //   let then = moment(new Date(`${txt_date300} 00:05`));

    //   const now = moment(new Date());

    //   const hours1 = then.diff(now, "hours");
    //   const minutes1 = then.diff(now, "minutes");
    //   const seconds1 = then.diff(now, "seconds");

    //   setHoursR300(hours1);
    //   setMinutesR300(minutes1 - Number(hours1) * 60);
    //   setSecondsR300(seconds1 - Number(minutes1) * 60);
    // }, 1000);

    // 25s
    var newDateObj25 = new Date(`${txt_date30}`);
    newDateObj25.setTime(newDateObj25.getTime() + 25 * 1000);
    const interval30 = setInterval(() => {
      let then = moment(newDateObj25);

      const now = moment(new Date(`${txt_date30}`));

      const hours1 = then.diff(now, "hours");
      const minutes1 = then.diff(now, "minutes");
      const seconds1 = then.diff(now, "seconds");

      setHoursR30(hours1);
      setMinutesR30(minutes1 - Number(hours1) * 60);
      setSecondsR30(seconds1 - Number(minutes1) * 60);
    }, 1000);
    // console.log("Update Component");

    return () => {
      if (interval1201) {
        clearInterval(interval1201);
      }
      if (interval430) {
        clearInterval(interval430);
      }
      if (interval930) {
        clearInterval(interval930);
      }
      if (interval1400) {
        clearInterval(interval1400);
      }

      if (interval30) {
        clearInterval(interval30);
      }
    };
  }, [
    alltrans30,
    alltrans300,
    alltran9_30,
    alltran12_01,
    alltran14_00,
    alltran4_30,
    secondsR30,
  ]);

  //4:30 to 00:00

  //test
  let date2 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 23:59:59`)
  );

  //4:30 to 00:00

  let date1_1 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 23:45:00`)
  );

  //12:01 to 2:00

  let date4 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:03:00`)
  );
  //12:01 to 2:00

  let date7 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:29:44`)
  );
  // 00:00:01  to 930
  let date5 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 00:00:01`)
  );
  let date8 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 9:30:00`)
  );
  // 00:00:01  to 930
  let date3 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 12:01:00`)
  );
  //12:01:00
  let date10 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:00:00`)
  );
  //14:00
  let date1 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 16:30:00`)
  );
  //

  let date9 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 10:00`)
  );

  let date11 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 14:30`)
  );

  let date1400 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 20:54:00`)
  );

  let date1359 = moment(
    new Date(`${moment(moment(new Date())).format("MM/DD/YYYY")} 20:53:45`)
  );
  let nowDate = moment(new Date());

  let changeTrans930;
  if (nowDate > date5 && nowDate < date8) {
    changeTrans930 = null;
  } else {
    changeTrans930 = alltran9_30;
  }

  // 12:01
  let changeTrans1201;

  if (nowDate > date5 && nowDate < date8) {
    changeTrans1201 = null;
  } else if (nowDate > date8 && nowDate < date3) {
    changeTrans1201 = alltrans30;
  } else {
    changeTrans1201 = alltran12_01;
  }
  // 930
  // 16:30
  let changeTrans1640;
  if (nowDate > date5 && nowDate < date10) {
    changeTrans1640 = null;
  } else if (nowDate > date10 && nowDate < date1) {
    changeTrans1640 = alltrans30;
  } else {
    changeTrans1640 = alltran4_30;
  }

  // 14:00
  let changeTrans1400;
  if (nowDate > date5 && nowDate < date10) {
    changeTrans1400 = null;
  } else {
    changeTrans1400 = alltran14_00;
  }

  return (
    <MasterUi>
      <Banner
        updateB={updateB}
        flashUpdate={flashUpdate}
        alltrans={bannerTrans}
        date8={date8}
        date9={date9}
        date3={date3}
        date4={date4}
        date10={date10}
        date11={date11}
        date1={date1}
        date2={date2}
        nowDate={nowDate}
        date1_1={date1_1}
        date7={date7}
        // result430={result430}
        // lastData430={lastData430}
        seconds={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? nowDate > date1 && nowDate < date2
              ? "00"
              : nowDate > date5 && nowDate < date8
              ? "00"
              : nowDate > date3 && nowDate < date10
              ? "00"
              : nowDate > date3 && nowDate < date1
              ? secondsR430
              : nowDate > date8 && nowDate < date3
              ? secondsR1201
              : "00"
            : "00"
        }
        minutes={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? nowDate > date1 && nowDate < date2
              ? "00"
              : nowDate > date5 && nowDate < date8
              ? "00"
              : nowDate > date3 && nowDate < date10
              ? "00"
              : nowDate > date3 && nowDate < date1
              ? minutesR430
              : nowDate > date8 && nowDate < date3
              ? minutesR1201
              : "00"
            : "00"
        }
        hours={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? nowDate > date1 && nowDate < date2
              ? "00"
              : nowDate > date5 && nowDate < date8
              ? "00"
              : nowDate > date3 && nowDate < date10
              ? "00"
              : nowDate > date3 && nowDate < date1
              ? hoursR430
              : nowDate > date8 && nowDate < date3
              ? hoursR1201
              : "00"
            : "00"
        }
      />
      <br />
      <br />

      <center>
        {" "}
        <div className="flex flex-col py-2 md:py-2">
          <center>
            <span className="">
              <span className="text-[28px] text-[#BA0202] font-display">
                {" "}
                {moment.utc(alltrans30?.tx_date).local().format("ll")}{" "}
              </span>{" "}
            </span>
          </center>
        </div>
      </center>
      <br></br>

      <Bitcoin2D
        alltrans={changeTrans1201}
        seconds={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? secondsR1201 < 0
              ? "00"
              : secondsR1201.toString().length === 1
              ? `0${secondsR1201}`
              : secondsR1201
            : "00"
        }
        minutes={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? minutesR1201.toString().length === 1
              ? `0${minutesR1201}`
              : minutesR1201
            : "00"
        }
        hours={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? hoursR1201.toString().length === 1
              ? `0${hoursR1201}`
              : hoursR1201
            : "00"
        }
        date="12:01 PM"
        date5={date5}
        date3={date3}
        date1={date1}
        date4={date4}
        date2={date2}
        date8={date8}
        nowDate={nowDate}
        type="1201PM"

        // updateB={updateB}
      />
      <Bitcoin2D
        alltrans={changeTrans1640}
        seconds={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? secondsR430 < 0
              ? "00"
              : secondsR430.toString().length === 1
              ? `0${secondsR430}`
              : secondsR430
            : "00"
        }
        minutes={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? minutesR430.toString().length === 1
              ? `0${minutesR430}`
              : minutesR430
            : "00"
        }
        hours={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? hoursR430.toString().length === 1
              ? `0${hoursR430}`
              : hoursR430
            : "00"
        }
        date="4:30 PM"
        date5={date5}
        date3={date3}
        date1={date1}
        date4={date4}
        date2={date2}
        date8={date8}
        nowDate={nowDate}
        type="430PM"
      />
      <Bitcoin2D
        alltrans={changeTrans930}
        seconds={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? secondsR930 < 0
              ? "00"
              : secondsR930.toString().length === 1
              ? `0${secondsR930}`
              : secondsR930
            : "00"
        }
        minutes={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? minutesR930.toString().length === 1
              ? `0${minutesR930}`
              : minutesR930
            : "00"
        }
        hours={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? hoursR930.toString().length === 1
              ? `0${hoursR930}`
              : hoursR930
            : "00"
        }
        date="9:30 AM"
        type="930PM"
      />
      <Bitcoin2D
        alltrans={changeTrans1400}
        seconds={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? secondsR1400 < 0
              ? "00"
              : secondsR1400.toString().length === 1
              ? `0${secondsR1400}`
              : secondsR1400
            : "00"
        }
        minutes={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? minutesR1400.toString().length === 1
              ? `0${minutesR1400}`
              : minutesR1400
            : "00"
        }
        hours={
          !["Mon"].some((e) => moment(new Date()).format("ddd") === e)
            ? hoursR1400.toString().length === 1
              ? `0${hoursR1400}`
              : hoursR1400
            : "00"
        }
        date="2:00 PM"
        type="1400PM"
      />
      {/* 4:30 */}

      <br />
      <br />
    </MasterUi>
  );
}

export default Home;
