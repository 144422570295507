import React from "react";
import "./Footer.css";
import { FaMapMarker, FaPhone, FaEnvelope, FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import imgDownload from "../../../assets/images/bitcoin/android.png";
import apkpure from "../../../assets/images/bitcoin/apkpure.png";
import play_store from "../../../assets/images/bitcoin/play_store.png";
const Footer = () => {
  return (
    <div className="  md:relative bottom-0 left-0 w-full">
      {/* <section className="info_section ">
        <div className="info_container ">
          <div className="container">
            <div className="info_logo">
              <center>
                <div className="flex ml-[5%]">
                  <div className="mt-[1.5%]">
                    <a
                      href={`https://estore.sgp1.digitaloceanspaces.com/new2d_lottery-1.0.2.apk`}
                      download
                    >
                      <img
                        src={imgDownload}
                        alt=""
                        style={{
                          width: "45%",
                          objectFit: "cover",
                        }}
                      />
                    </a>
                  </div>
                  <div className="mt-[1.5%] ml-[6%]">
                    <a
                      href={`https://apkpure.com/n2d-2d-lottery/com.mybet.new2d?fbclid=IwAR1jA74Q_Jt8E3WDhLZFMCkzkWzeI6aiVlzZNBVVA3WB2yEAnOSQCJDIeGo`}
                      download
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img
                        src={apkpure}
                        alt=""
                        style={{
                          width: "38%",
                          objectFit: "cover",
                          borderRadius: "0.5rem",
                        }}
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={`https://play.google.com/store/apps/details?id=com.mybet.new2d&fbclid=IwAR0QN4XVdfREeyx4AFPfIC_w6YQHcB2lpnN5sfmBvTluTdbBfTYDLcKTJMY`}
                      download
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <img
                        src={play_store}
                        alt=""
                        style={{ width: "35%", objectFit: "cover" }}
                      />
                    </a>
                  </div>
                </div>
              </center>
            </div>
          </div>
        </div>
      </section> */}

      <section className="w-full absolute bottom-0 bg-[#FB9148] flex justify-center items-center  py-2">
        <p>
          <span className="text-white"> Developed By </span> &nbsp;
          <Link to={"/"} className="text-[#BA0202]">
            New 2D Lottery
          </Link>
        </p>
      </section>
    </div>
  );
};

export default Footer;
