import Footer from "../home/footer/Footer";
import NavBar from "../home/navbar/NavBar";

function MasterUi(props) {
  const { children } = props;
  return (
    <div
      className={`relative bg-cover h-full	 hero-area bg-url font-display grid grid-rows-[100px_1fr_50px] `}
      style={{ backgroundColor: "#fff" }}
    >
      <NavBar />
      <div className="min-h-screen">{children}</div>
      <Footer />
    </div>
  );
}

export default MasterUi;
