import React from "react";
import MasterUi from "../master-ui/MasterUi";
import downloadImage from "../../assets/images/download/download_image.svg";
import appStore from "../../assets/images/download/app_store.svg";
import playStore from "../../assets/images/download/play_store.svg";
import apk_pure from "../../assets/images/download/apk_pure.svg";
import { Link } from "react-router-dom";

const Download = () => {
  return (
    <MasterUi>
      <div className="relative h-auto flex flex-col-reverse xl:flex xl:flex-row justify-between items-center w-[90%] xl:w-full 2xl:w-[95rem] overflow-x-hidden pt-20 pb-40 xl:pt-40 mx-auto gap-y-10 xl:gap-y-0">
        <div className=" w-[80%] xl:w-[537px] xl:h-[450px] flex flex-col justify-between items-start xl:pl-20">
          <p className="text-[30px] xl:text-[56px] font-bold">
            Download Our <br /> <span className="text-[#BA0202] ">Mobile</span>
            &nbsp; App!
          </p>
          <p className="text-[#353535] text-[12px]  xl:text-[18px] my-10">
            Enim donec semper elit odio. Quis id sed convallis habitasse et,
            cursus dictumst morbi quis. Quis id sed convallis habitasse et,
            cursus dictumst morbi quis.
          </p>
          <div className="md:flex   w-full justify-between items-center">
            <Link
              to="https://estore.sgp1.digitaloceanspaces.com/new2d_lottery-1.0.2.apk"
              target="_blank"
              download
              rel="noreferrer"
            >
              {" "}
              <img src={appStore} alt="" />
            </Link>
            <Link
              to="https://play.google.com/store/apps/details?id=com.mybet.new2d&fbclid=IwAR0QN4XVdfREeyx4AFPfIC_w6YQHcB2lpnN5sfmBvTluTdbBfTYDLcKTJMY"
              target="_blank"
              download
              rel="noreferrer"
            >
              {" "}
              <img
                src={playStore}
                alt=""
                className="xl:mx-5 my-2 xl:my-0"
              />{" "}
            </Link>
            <Link
              to="https://apkpure.com/n2d-2d-lottery/com.mybet.new2d?fbclid=IwAR1jA74Q_Jt8E3WDhLZFMCkzkWzeI6aiVlzZNBVVA3WB2yEAnOSQCJDIeGo"
              target="_blank"
              download
              rel="noreferrer"
            >
              {" "}
              <img src={apk_pure} alt="" />
            </Link>
          </div>
        </div>

        <img src={downloadImage} alt="" />
      </div>
    </MasterUi>
  );
};

export default Download;
