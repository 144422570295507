import axios from "axios";
import { GET_HISTORY_ALL } from "./types";

import { endpoint, prodEndpoint } from "../config";

const URL = process.env.NODE_ENV === "development" ? endpoint : prodEndpoint;

const historyAll = () => {
  // console.log(data);

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}api/v1/tx/histories?date_type=120&tx_type=BTC_120`)
        .then((response) => {
          dispatch({
            type: GET_HISTORY_ALL,
            payload: response.data,
          });
        });
    } catch (error) {
      // console.log(error);
    }
  };
};

const resultHistory = async ({
  tx_type,
  date_type,
  page,
  tx_date,
  endDate,
}) => {
  try {
    let end_point = `${URL}api/v1/tx/histories?page=${page}&date_type=${date_type}&tx_type=${tx_type}`;
    if (tx_date && endDate) {
      // end_point = `https://new2d.mybet123.com/api/v1/tx/histories?date_type=${date_type}&tx_type=${tx_type}&page=${page}&endDate=${endDate}`;
      end_point = `${URL}api/v1/tx/histories?date_type=${date_type}&tx_type=${tx_type}&page=${page}&tx_date=${tx_date}&endDate=${endDate}`;
    }
    const result = await axios.get(end_point);
    return result.data;
  } catch (error) {
    // console.log("getLastTx error");
    // console.log(error);
  }
};

export { historyAll, resultHistory };
