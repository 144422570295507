import moment from "moment";
import React, { useEffect, useState } from "react";
import { resultHistory } from "../../actions/historyAction";
import TabsRender from "../../reusable/tabs/TabsRender";
import Bitcoin2DLive from "../live/bitcoin-2d-live/Bitcoin2DLive";
import MasterUi from "../master-ui/MasterUi";

const Result = (props) => {
  const { alltrans, lasttrans30, hours, minutes, seconds, type } = props;

  const [result1201, setResult1201] = useState(null);
  const [result430, setResult430] = useState(null);
  const [resultDate, setResultDate] = useState(null);

  const get_History = async () => {
    const result1201 = await resultHistory({
      tx_type: "BTC_2D",
      date_type: "1201",
      page: 1,
    });
    setResult1201(result1201);

    const result430 = await resultHistory({
      tx_type: "BTC_2D",
      date_type: "1630",
      page: 1,
    });
    setResult430(result430);

    // const resultDate1201 = await resultHistory({
    //   tx_type: "BTC_2D",
    //   date_type: "1201",
    //   page: 1,
    //   tx_date: "2022-01-26",
    // });

    // const resultDate430 = await resultHistory({
    //   tx_type: "BTC_2D",
    //   date_type: "1630",
    //   page: 1,
    //   tx_date: "2022-01-26",
    // });

    // console.log("ResultDate1201", resultDate1201);
    // const resulttwo = {
    //   odd1201: resultDate1201[0].odds,
    //   odd1201Date: resultDate1201[0].tx_date,
    //   odd1201Hash: resultDate1201[0].hash,
    //   odd430: resultDate430[0].odds,
    //   odd430Date: resultDate430[0].tx_date,
    //   odd430Hash: resultDate430[0].hash,
    // };

    // setResultDate(resulttwo);
  };

  useEffect(() => {
    get_History();
  }, []);
  return (
    <>
      <MasterUi>
        <br />
        <br />
        <br />
        <br />
        <Bitcoin2DLive
          alltrans={alltrans}
          lasttrans={lasttrans30}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          date={moment(alltrans?.tx_date).format("lll")}
          type={type}
        />

        <br />
        <br />
        <br />
        <TabsRender
          result1201={result1201}
          result430={result430}
          resultDate={resultDate}
        />
      </MasterUi>
    </>
  );
};

export default Result;
