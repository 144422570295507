import React, { useEffect, useState } from "react";
import "./Bitcoin2D.css";
import Moment from "moment";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Bitcoin2D = (props) => {
  const {
    alltrans,
    date,
    hours,
    minutes,
    seconds,
    lasttrans,
    date5,
    date3,
    date1,
    date4,
    date1_1,
    date2,
    nowDate,
    date8,
    type,
  } = props;

  const textlen = alltrans?.hash.length;

  const text1 = alltrans?.hash.slice(0, 5);

  const text2 = alltrans?.hash.slice(textlen - 4, textlen - 1);
  const [stable, setStable] = useState(1);

  useEffect(() => {
    if (alltrans) {
      setTimeout(() => {
        setStable(0);
      }, 5000);
      setStable(1);
    }
  }, [alltrans]);

  let condi430_23_59 = nowDate > date1 && nowDate < date2;
  let condi001_12_01 = nowDate > date5 && nowDate < date3;
  let condi1201_14_00 = nowDate > date3 && nowDate < date8;
  let condi1400_16_30 = nowDate > date8 && nowDate < date1;
  let condi001_1400 = nowDate > date5 && nowDate < date8;
  // console.log("Hours", seconds);
  let hourPercent = (hours / 24) * 100;
  let minutePercent = (minutes / 60) * 100;
  let secondPercent = seconds === "00" ? 0 : ((60 - seconds) / 60) * 100;

  let hmsArray = [
    {
      id: 1,
      text1: hours,
      text2: "HOUR",
      percent: hourPercent,
    },
    {
      id: 2,
      text1: minutes,
      text2: "MINUTE",
      percent: minutePercent,
    },
    {
      id: 3,
      text1: seconds,
      text2: "SECOND",
      percent: secondPercent,
    },
  ];
  return (
    <div className="container mt-10">
      <div
        className="rounded h-auto xl:w-7/12 px-2 md:px-2 	 mx-auto bg-[#FB9148]"
        style={{
          boxShadow:
            "rgb(60 64 67 / 30%) 0px 1px 2px 0px, rgb(60 64 67 / 15%) 0px 1px 3px 1px",
        }}
      >
        <span className="float-right  rounded-tl-[20px] rounded-br-[20px] px-4 py-1 -mt-9 md:-mt-9 -mr-2 text-white bg-[#FB9148] font-display border border-black ">
          {date}
        </span>
        <div className="flex justify-between items-center xl:px-10">
          <div className="w-[4.375rem] h-[4.375rem] bg-white border-2 border-[#BA0202] rounded-full grid  place-items-center  md:my-2 my-3">
            <span
              className={`text-[#BA0202] font-display  text-[30px] font-bold ${
                type === "430PM"
                  ? condi001_12_01
                    ? ""
                    : condi1201_14_00
                    ? ""
                    : condi430_23_59
                    ? ""
                    : condi1400_16_30
                    ? stable
                      ? ""
                      : "numAnimate"
                    : ""
                  : type === "1201PM"
                  ? condi001_12_01
                    ? stable
                      ? ""
                      : "numAnimate"
                    : ""
                  : ""
              }`}
            >
              {type === "1201PM"
                ? alltrans
                  ? alltrans?.odds === 0
                    ? condi001_12_01
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : alltrans?.odds.toString().length === 1
                    ? condi001_12_01
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : condi001_12_01
                    ? "- -"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? alltrans?.odds
                    : "- -"
                  : "- -"
                : type === "430PM"
                ? alltrans
                  ? alltrans?.odds === 0
                    ? condi001_1400 || condi1400_16_30
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : alltrans?.odds.toString().length === 1
                    ? condi001_1400 || condi1400_16_30
                      ? "- -"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : condi001_1400 || condi1400_16_30
                    ? "- -"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? alltrans?.odds
                    : "- -"
                  : "- -"
                : type === "1400PM"
                ? alltrans
                  ? alltrans?.odds === 0
                    ? Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : alltrans?.odds.toString().length === 1
                    ? Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? alltrans?.odds
                    : "- -"
                  : "- -"
                : type === "930PM"
                ? alltrans
                  ? alltrans?.odds === 0
                    ? Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : alltrans?.odds.toString().length === 1
                    ? Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                      ? `0${alltrans?.odds}`
                      : "- -"
                    : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                      Moment().format("YYYY-MM-DD")
                    ? alltrans?.odds
                    : "- -"
                  : "- -"
                : ""}
            </span>
          </div>
          <div className="w-[70%]">
            <div className="grid grid-cols-2 md:grid-cols-3 w-full  font-display text-[16px]">
              <div className="flex flex-col pt-1">
                <center>
                  <div className="mb-2">
                    <span className="text-md text-base text-white mb-6">
                      TXD ID
                    </span>
                  </div>
                </center>
              </div>

              <div className=" flex flex-col pt-1">
                <center>
                  <div className="mb-2">
                    <span className="text-md text-base text-white">Value</span>
                  </div>
                </center>
              </div>
              <div className="   md:flex md:flex-col  hidden  md:visible  pt-1">
                <center>
                  <span className="md:text-md text-base text-white">
                    Next Draw
                  </span>
                </center>
              </div>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 w-full  font-display text-[16px]">
              <div className="flex flex-col py-2 md:py-2">
                <center>
                  <a
                    href={`https://www.blockchain.com/btc/tx/${alltrans?.hash}`}
                    target="_blank"
                    className={`text-black  ${
                      type === "430PM"
                        ? condi001_12_01
                          ? ""
                          : condi1201_14_00
                          ? ""
                          : condi430_23_59
                          ? ""
                          : condi1400_16_30
                          ? stable
                            ? ""
                            : "numAnimate"
                          : ""
                        : type === "1201PM"
                        ? condi001_12_01
                          ? stable
                            ? ""
                            : "numAnimate"
                          : ""
                        : ""
                    }`}
                    rel="noreferrer"
                  >{`${
                    text1 === undefined
                      ? "*****"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? text1
                      : "*****"
                  }***${
                    text2 === undefined
                      ? "*****"
                      : Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                      ? text2
                      : "*****"
                  }`}</a>
                </center>
              </div>

              <div className=" flex flex-col py-2 md:py-2">
                <center>
                  <span
                    className={` ${
                      type === "430PM"
                        ? condi001_12_01
                          ? ""
                          : condi1201_14_00
                          ? ""
                          : condi430_23_59
                          ? ""
                          : condi1400_16_30
                          ? stable
                            ? ""
                            : "numAnimate"
                          : ""
                        : type === "1201PM"
                        ? condi001_12_01
                          ? stable
                            ? ""
                            : "numAnimate"
                          : ""
                        : ""
                    }`}
                  >
                    {" "}
                    {alltrans?.value
                      ? Moment(alltrans?.tx_date).format("YYYY-MM-DD") ===
                        Moment().format("YYYY-MM-DD")
                        ? alltrans?.value
                        : " - - - - - -"
                      : lasttrans?.result?.value
                      ? Moment(lasttrans?.result?.tx_date).format(
                          "YYYY-MM-DD"
                        ) === Moment().format("YYYY-MM-DD")
                        ? lasttrans?.result?.value
                        : " - - - - - -"
                      : " - - - - - -"}{" "}
                  </span>{" "}
                </center>
              </div>
              <div className="  md:flex md:flex-col  hidden  md:visible  py-2 md:py-2">
                <center className="grid grid-cols-3">
                  {hmsArray.map(({ id, text1, text2, percent }) => (
                    <div key={id}>
                      <div className="w-[1.75rem] h-[1.75rem mb-3 bg-white rounded-full ">
                        <CircularProgressbar
                          value={percent}
                          text={`${text1}`}
                          strokeWidth={5}
                          styles={buildStyles({
                            pathColor: "#BA0202 ",
                            trailColor: "#fff",
                            strokeLinecap: "butt",
                            textSize: "40px",
                            textColor: "black",
                            background: "#3e98c7",
                          })}
                        />
                      </div>
                      <p className="text-[6px] text-white">{text2}</p>
                    </div>
                  ))}
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default Bitcoin2D;
