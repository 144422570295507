import React, { useEffect } from "react";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DateRangePicker from "@mui/lab/DateRangePicker";
import DatePicker from "@mui/lab/DatePicker";
import { makeStyles } from "@mui/styles";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { MdOutlineExpandMore } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { historyAll, resultHistory } from "../../actions/historyAction";
import {
  convert1,
  convert2,
  convert3,
  convert4,
  convert5,
} from "../../actions/convertAction";
import moment from "moment";
import { Button } from "@mui/material";
import { Form, Formik, isEmptyArray } from "formik";
import { border } from "@mui/system";
import DatePickerRange from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar2Check } from "react-icons/bs";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "orange",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "orange",
    },
    "& .MuiTouchRipple-root": {
      borderColor: "none",
      outline: "none",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "orange",
    },
  },
});
const Tabs1 = ({ color, allhistory }) => {
  const classes = useStyles();
  // console.log("Tabs1", allhistory);

  const time1 =
    allhistory &&
    allhistory.filter((h) => moment(h.tx_date).format("LT") === "12:01 PM");

  const time2 =
    allhistory &&
    allhistory.filter((h) => moment(h.tx_date).format("LT") === "4:30 PM");

  const [openTab, setOpenTab] = React.useState(0);
  const [value, setValue] = React.useState(new Date());

  return (
    <>
      <div className="flex flex-wrap">
        <div className="md:w-5/12	w-11/12 mx-auto md:max-w-4xl md:mx-auto">
          <ul
            className="grid grid-cols-3 md:max-w-xl mx-auto sm:flex md:flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              <a
                className={
                  "hover:text-[#353535] text-xl font-bold uppercase md:px-5 md:py-3 py-2 shadow-lg rounded block leading-normal " +
                  (openTab === 1
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                12:01 PM
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center ">
              <a
                className={
                  "hover:text-[#353535] text-xl font-bold uppercase md:px-5 md:py-3 py-2 shadow-lg rounded block leading-normal " +
                  (openTab === 2
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                4:30 PM
              </a>
            </li>
            <li className="-mb-px last:mr-0 flex-auto text-center ">
              <a
                className={
                  "hover:text-[#353535] text-xl font-bold uppercase md:px-5 md:py-3 py-2 shadow-lg rounded block leading-normal " +
                  (openTab === 0
                    ? "text-white bg-" + color + "-600"
                    : "text-" + color + "-600 bg-white")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(0);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                ALL
              </a>
            </li>
          </ul>
          <center>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                startText="Check-in"
                endText="Check-out"
                value={value}
                xs
                onChange={(newValue) => {
                  setValue(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <>
                    <TextField
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      className={classes.root}
                      {...startProps}
                      sx={{
                        svg: { color: "#fff", border: "none", outline: "none" },
                        input: {
                          color: "#fff",
                          outline: "none",
                        },
                        label: { color: "#fff", outline: "none" },
                      }}
                    />
                    <p>to</p>
                    <TextField
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      className={classes.root}
                      {...endProps}
                      sx={{
                        svg: { color: "#fff", border: "none", outline: "none" },
                        input: {
                          color: "#fff",
                          outline: "none",
                        },
                        label: { color: "#fff", outline: "none" },
                      }}
                    />
                  </>
                )}
              />
            </LocalizationProvider>
          </center>
          <br />
          <br />
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full  mb-6 shadow-lg rounded">
            <div className="md:px-4 md:py-4 px-1 py-1 flex-auto">
              <div className="tab-content tab-space">
                {/* initial state */}
                <div
                  className={`${
                    openTab === 0 ? "block" : "hidden"
                  } grid grid-cols-2 gap-4`}
                  id="link1"
                >
                  {allhistory &&
                    allhistory.map((h, i) => (
                      <div
                        key={i}
                        className=" pt-3 mb-3 pr-1  md:h-32 h-36 text-center rounded"
                        style={{ backgroundColor: "#072358" }}
                      >
                        <center>
                          <AiOutlineCheckCircle className="text-3xl	text-cyan-700" />
                        </center>
                        <div className="grid grid-cols-2 mt-3">
                          <p className="text-white text-3xl mt-2 font-bold">
                            {h.odds}
                          </p>
                          <p className="text-white text-sm mt-1 md:mt-0">
                            {
                              moment(h.tx_date).format("LL") // January 22, 2022
                            }{" "}
                            <br />{" "}
                            <span className="leading-7	">
                              {moment(h.tx_date).format("LT")}
                            </span>
                          </p>
                        </div>
                      </div>
                    ))}
                </div>

                <div
                  className={`${
                    openTab === 0 ? "block" : "hidden"
                  } grid grid-cols-1 gap-4 ${
                    time1 && time1.length === 0 ? "mt-0" : "mt-4"
                  }`}
                  id="link1"
                >
                  {allhistory && allhistory.length === 0 ? (
                    <center>
                      {" "}
                      <span className="text-center	">No History</span>
                    </center>
                  ) : (
                    <center>
                      <button class="animate-bounce bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {/* <svg
                          class="fill-current w-4 h-4 mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                        </svg> */}
                        <MdOutlineExpandMore className="text-1xl  " /> &nbsp;
                        <span className="  text-xs">LOAD MORE</span>
                      </button>
                    </center>
                  )}
                </div>

                {/* 12:01 PM */}
                <div
                  className={`${
                    openTab === 1 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  {time1 &&
                    time1.map((t1, i) => (
                      <div
                        key={i}
                        className="mb-3 pt-3   md:h-32 h-36 text-center rounded"
                        style={{ backgroundColor: "#072358" }}
                      >
                        <center>
                          <AiOutlineCheckCircle className="text-3xl	text-cyan-700" />
                        </center>
                        <div className="grid grid-cols-2 mt-3">
                          <p className="text-white text-3xl mt-2 font-bold">
                            {t1.odds}
                          </p>
                          <p className="text-white text-sm">
                            {
                              moment(t1.tx_date).format("LL") // January 22, 2022
                            }{" "}
                            <br /> <span className="leading-7	">12:01 PM</span>
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className={`${
                    openTab === 1 ? "block" : "hidden"
                  } grid grid-cols-1 gap-4 ${
                    time1 && time1.length === 0 ? "mt-0" : "mt-4"
                  }`}
                  id="link1"
                >
                  {time1 && time1.length === 0 ? (
                    <center>
                      {" "}
                      <span className="text-center	">No History</span>
                    </center>
                  ) : (
                    <center>
                      <button class="animate-bounce bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {/* <svg
                          class="fill-current w-4 h-4 mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                        </svg> */}
                        <MdOutlineExpandMore className="text-1xl  " /> &nbsp;
                        <span className="  text-xs">LOAD MORE</span>
                      </button>
                    </center>
                  )}
                </div>

                {/* 4:30 */}
                {time2 &&
                  time2.map((t2, i) => (
                    <div
                      key={i}
                      className={`${
                        openTab === 2 ? "block" : "hidden"
                      } grid grid-cols-1`}
                      id="link2"
                    >
                      <div
                        className="mb-3 pt-3   h-32 text-center rounded"
                        style={{ backgroundColor: "#072358" }}
                      >
                        <center>
                          <AiOutlineCheckCircle className="text-3xl	text-cyan-700" />
                        </center>
                        <div className="grid grid-cols-2 mt-3">
                          <p className="text-white text-3xl mt-2 font-bold">
                            {t2.odds}
                          </p>
                          <p className="text-white text-sm">
                            {
                              moment(t2.tx_date).format("LL") // January 22, 2022
                            }{" "}
                            <br /> <span className="leading-7	">4:30 PM</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}

                <div
                  className={`${
                    openTab === 2 ? "block" : "hidden"
                  } grid grid-cols-1 gap-4 ${
                    time1 && time1.length === 0 ? "mt-0" : "mt-4"
                  }`}
                  id="link1"
                >
                  {time1 && time1.length === 0 ? (
                    <center>
                      {" "}
                      <span className="text-center	">No History</span>
                    </center>
                  ) : (
                    <center>
                      <button class="animate-bounce bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                        {/* <svg
                          class="fill-current w-4 h-4 mr-2"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                        </svg> */}
                        <MdOutlineExpandMore className="text-1xl  " /> &nbsp;
                        <span className="  text-xs">LOAD MORE</span>
                      </button>
                    </center>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const Tabs2 = ({ color, result1201, result430 }) => {
  const classes = useStyles();

  const [openTab, setOpenTab] = React.useState(0);
  // const [valueDate, setValueDate] = React.useState(new Date());
  const [page, setPage] = React.useState(1);
  const [dateStart, setDateStart] = React.useState(new Date());
  const [dateEnd, setDateEnd] = React.useState(new Date());
  const [resultOneDateFilter1201, setResultOneDateFilter1201] =
    React.useState(null);
  const [resultOneDateFilter1630, setResultOneDateFilter1630] =
    React.useState(null);

  const get_History_Date = async (dateStart, dateEnd, page) => {
    const resultDate1201 = await resultHistory({
      tx_type: "BTC_2D",
      date_type: "1201",
      page: page,
      tx_date: moment(dateStart).format("YYYY-MM-DD"),
      endDate: moment(dateEnd).format("YYYY-MM-DD"),
    });

    setResultOneDateFilter1201(resultDate1201);

    const resultDate430 = await resultHistory({
      tx_type: "BTC_2D",
      date_type: "1630",
      page: page,
      tx_date: moment(dateStart).format("YYYY-MM-DD"),
      endDate: moment(dateEnd).format("YYYY-MM-DD"),
    });
    setResultOneDateFilter1630(resultDate430);

    // const resulttwo = {
    //   odd1201: isEmptyArray(resultDate1201) ? "- -" : resultDate1201[0].odds,
    //   odd1201Date: isEmptyArray(resultDate1201)
    //     ? "- -"
    //     : resultDate1201[0].tx_date,
    //   odd1201Hash: isEmptyArray(resultDate1201)
    //     ? undefined
    //     : resultDate1201[0].hash,
    //   odd430: isEmptyArray(resultDate430) ? "- -" : resultDate430[0].odds,
    //   odd430Date: isEmptyArray(resultDate430)
    //     ? "- -"
    //     : resultDate430[0].tx_date,
    //   odd430Hash: isEmptyArray(resultDate430)
    //     ? undefined
    //     : resultDate430[0].hash,
    // };

    // setValueRDate(resulttwo);
  };

  useEffect(() => {
    get_History_Date(dateStart, dateEnd, page);
  }, [dateStart, dateEnd, page]);

  function onChangeHandler(value) {
    setDateStart(value[0]);
    setDateEnd(value[1]);
  }
  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <div
        style={{
          display: "inline-flex",
        }}
      >
        <label onClick={props.onClick} ref={ref} className="mx-2">
          {props.value || props.placeholder}
        </label>
        <BsCalendar2Check
          onClick={props.onClick}
          style={{ fontSize: "1.5rem" }}
        />
      </div>
    );
  });

  return (
    <>
      <div className="flex flex-wrap">
        <div className="md:w-6/12	w-11/12 mx-auto  md:mx-auto">
          <center>
            <a
              href="#link2"
              data-toggle="tab"
              role="tablist"
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(0);
              }}
              className="hover:text-[#353535] text-[#353535] focus:text-[#353535]"
            >
              <div style={{ position: "relative" }}>
                <DatePickerRange
                  id="dateStartEnd"
                  selectsRange={true}
                  startDate={dateStart}
                  endDate={dateEnd}
                  onChange={onChangeHandler}
                  dateFormat="dd MMM yyyy"
                  customInput={<CustomInput />}
                />
              </div>
              {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={valueDate}
                  xs
                  onChange={(newValue) => {
                    setValueDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{
                        style: { color: "black" },
                      }}
                      className={classes.root}
                      {...params}
                      sx={{
                        svg: {
                          color: "black",
                          border: "none",
                          outline: "none",
                        },
                        input: {
                          color: "black",
                          outline: "none",
                        },
                        label: { color: "black", outline: "none" },
                      }}
                    />
                  )}
                />
              </LocalizationProvider> */}
            </a>
          </center>
          <ul
            className="md:grid grid-cols-2 md:max-w-xl mx-auto flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"
            role="tablist"
          >
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
              {/* {console.log(
                "text-[#000]",
                openTab === 1 ? "text-[#000]" : "text-[#fff]"
              )} */}
              <span
                className={` text-xl font-bold uppercase md:px-5 md:py-3 py-2 shadow-lg rounded block leading-normal ${
                  openTab === 1 ? "text-[#FB9148]" : "text-[#000]"
                }`}
                onClick={(e) => {
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                12:01 PM
              </span>
            </li>
            <li className="-mb-px mr-2 last:mr-0 flex-auto text-center ">
              <span
                className={
                  " text-xl font-bold uppercase md:px-5 md:py-3 py-2 shadow-lg rounded block leading-normal " +
                  (openTab === 2 ? "text-[#FB9148]" : "text-[#000]")
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                4:30 PM
              </span>
            </li>
          </ul>

          <br />
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full  rounded">
            <div className=" flex-auto">
              <div className="">
                {/* initial state */}
                <div
                  className={`${
                    openTab === 0 ? "block" : "hidden"
                  } grid grid-cols-2 gap-x-2`}
                  id="link1"
                >
                  <div className="grid grid-cols-1 gap-y-3 ">
                    {resultOneDateFilter1201 &&
                      resultOneDateFilter1201.map((valueRDate, i) => (
                        <div
                          className=" grid grid-cols-2 text-center bg-orange-500 rounded "
                          key={i}
                        >
                          <p
                            className="text-white md:text-xl text-xs font-bold  
                        "
                          >
                            TXD ID <br />
                            <a
                              href={`https://www.blockchain.com/btc/tx/${valueRDate?.hash}`}
                              target="_blank"
                              className={`text-[#353535] text-sm whitespace-nowrap`}
                              rel="noreferrer"
                            >{`${
                              valueRDate?.hash === undefined
                                ? "*****"
                                : valueRDate?.hash.slice(0, 5)
                            }***${
                              valueRDate?.hash === undefined
                                ? "*****"
                                : valueRDate?.hash.slice(
                                    valueRDate?.hash.length - 4,
                                    valueRDate?.hash.length - 1
                                  )
                            }`}</a>
                          </p>
                          <p className="text-white md:text-6xl text-center text-4xl font-bold">
                            {valueRDate?.odds.toString().length === 1
                              ? `0${valueRDate?.odds}`
                              : valueRDate?.odds
                              ? valueRDate?.odds
                              : "- -"}
                          </p>
                          <div className=" w-full col-span-2 py-2  border-t  border-t-cyan-50">
                            <p
                              className="text-white md:text-xl text-xs font-bold 
                        "
                            >
                              {moment(valueRDate?.tx_date).format("ll")} 12:01
                              PM
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className="grid grid-cols-1  gap-y-3 ">
                    {resultOneDateFilter1630 &&
                      resultOneDateFilter1630.map((valueRDate, i) => (
                        <div
                          className=" grid grid-cols-2 text-center bg-orange-500 rounded "
                          key={i}
                        >
                          <p
                            className="text-white md:text-xl text-xs font-bold  
                        "
                          >
                            TXD ID <br />
                            <a
                              href={`https://www.blockchain.com/btc/tx/${valueRDate?.hash}`}
                              target="_blank"
                              className={`text-[#353535] text-sm whitespace-nowrap`}
                              rel="noreferrer"
                            >{`${
                              valueRDate?.hash === undefined
                                ? "*****"
                                : valueRDate?.hash.slice(0, 5)
                            }***${
                              valueRDate?.hash === undefined
                                ? "*****"
                                : valueRDate?.hash.slice(
                                    valueRDate?.hash.length - 4,
                                    valueRDate?.hash.length - 1
                                  )
                            }`}</a>
                          </p>
                          <p className="text-white md:text-6xl text-center text-4xl font-bold">
                            {valueRDate?.odds.toString().length === 1
                              ? `0${valueRDate?.odds}`
                              : valueRDate?.odds
                              ? valueRDate?.odds
                              : "- -"}
                          </p>
                          <div className=" w-full col-span-2 py-2 border-t  border-t-cyan-50">
                            <p
                              className="text-white md:text-xl text-xs font-bold 
                        "
                            >
                              {moment(valueRDate?.tx_date).format("ll")} 4:30 PM
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                {/* <div
                  className={`${
                    openTab === 0 ? "block" : "hidden"
                  } grid grid-cols-1 gap-4 mt-4`}
                  id="link1"
                >
                  <center>
                    <button class="animate-bounce bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                      <MdOutlineExpandMore className="text-1xl  " /> &nbsp;
                      <span className="  text-xs">LOAD MORE</span>
                    </button>
                  </center>
                </div> */}

                {/* 12:01 PM */}
                <div
                  className={`${
                    openTab === 1 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  {result1201 &&
                    result1201
                      .sort((a, b) => (a.id < b.id ? 1 : -1))
                      .map((res, index) => (
                        <div key={index}>
                          <div className="py-2 mb-6 grid grid-cols-2   text-center bg-orange-500 rounded  ">
                            <p
                              className="text-white md:text-xl text-xs font-bold  py-2
                      "
                            >
                              TXD ID <br />
                              <a
                                href={`https://www.blockchain.com/btc/tx/${res?.hash}`}
                                target="_blank"
                                className={`text-[#353535] text-sm`}
                                rel="noreferrer"
                              >{`${
                                res?.hash.slice(0, 5) === undefined
                                  ? "*****"
                                  : res?.hash.slice(0, 5)
                              }***${
                                res?.hash.slice(
                                  res?.hash.length - 4,
                                  res?.hash.length - 1
                                ) === undefined
                                  ? "*****"
                                  : res?.hash.slice(
                                      res?.hash.length - 4,
                                      res?.hash.length - 1
                                    )
                              }`}</a>
                            </p>
                            <p className="text-white md:text-6xl text-center text-4xl font-bold py-2">
                              {res?.odds.toString().length === 1
                                ? `0${res?.odds}`
                                : res?.odds
                                ? res?.odds
                                : "- -"}
                            </p>
                            <div className=" w-full col-span-2 py-2 border-t  border-t-cyan-50">
                              <p
                                className="text-white md:text-xl text-xs font-bold 
                      "
                              >
                                {
                                  moment.utc(res.tx_date).local().format("ll") // January 26, 2022 5:43 PM
                                }{" "}
                                12:01 PM
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>

                {/* <div
                  className={`${
                    openTab === 1 ? "block" : "hidden"
                  } grid grid-cols-1 gap-4 mt-4`}
                  id="link1"
                >
                  <center>
                    <button class="animate-bounce bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                     
                      <MdOutlineExpandMore className="text-1xl  " /> &nbsp;
                      <span className="  text-xs">LOAD MORE</span>
                    </button>
                  </center>
                </div> */}

                {/* 4:30 */}
                <div
                  className={`${
                    openTab === 2 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  {result430 &&
                    result430
                      .sort((a, b) => (a.id < b.id ? 1 : -1))
                      .map((res, i) => (
                        <div key={i}>
                          <div className="py-2 mb-6 grid grid-cols-2   text-center bg-orange-500 rounded  ">
                            <p
                              className="text-white md:text-xl text-base font-bold  py-2
                      "
                            >
                              TXD ID <br />
                              <a
                                href={`https://www.blockchain.com/btc/tx/${res?.hash}`}
                                target="_blank"
                                className={`text-[#353535] text-sm `}
                                rel="noreferrer"
                              >{`${
                                res?.hash.slice(0, 5) === undefined
                                  ? "*****"
                                  : res?.hash.slice(0, 5)
                              }***${
                                res?.hash.slice(
                                  res?.hash.length - 4,
                                  res?.hash.length - 1
                                ) === undefined
                                  ? "*****"
                                  : res?.hash.slice(
                                      res?.hash.length - 4,
                                      res?.hash.length - 1
                                    )
                              }`}</a>
                            </p>
                            <p className="text-white md:text-6xl text-center text-4xl font-bold py-2">
                              {res?.odds.toString().length === 1
                                ? `0${res?.odds}`
                                : res?.odds
                                ? res?.odds
                                : "- -"}

                              {/* {res.odds
                                ? res.odds.toString().length === 1
                                  ? `0${res.odds}`
                                  : res.odds
                                : "??"} */}
                            </p>
                            <div className=" w-full col-span-2 py-2 border-t  border-t-cyan-50">
                              <p
                                className="text-white md:text-xl text-xs font-bold 
                      "
                              >
                                {
                                  moment.utc(res.tx_date).local().format("ll") // January 26, 2022 5:43 PM
                                }{" "}
                                4:30 PM
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>

                {/* <div
                  className={`${
                    openTab === 2 ? "block" : "hidden"
                  } grid grid-cols-1 gap-4 mt-4`}
                  id="link1"
                >
                  <center>
                    <button class="animate-bounce bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center">
                      <MdOutlineExpandMore className="text-1xl  " /> &nbsp;
                      <span className="  text-xs">LOAD MORE</span>
                    </button>
                  </center>
                </div> */}

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Tabs3 = ({
  color,
  checkData,
  convert1,
  convert1_result,
  convert2,
  convert2_result,
  convert3,
  convert3_result,
  convert4,
  convert4_result,
  convert5,
  convert5_result,
}) => {
  const classes = useStyles();

  const [openTab, setOpenTab] = React.useState(1);
  const [after, setAfter] = React.useState(0);

  const stepArray = [
    {
      id: 1,
      step_name: "STEP1",
    },
    {
      id: 2,
      step_name: "STEP2",
    },
    {
      id: 3,
      step_name: "STEP3",
    },
    {
      id: 4,
      step_name: "STEP4",
    },
    {
      id: 5,
      step_name: "STEP5",
    },
  ];

  return (
    <>
      <br />
      <br />
      <div className="flex flex-wrap md:mb-60 h-auto mb-36">
        <div className="md:w-8/12	w-11/12 mx-auto md:max-w-4xl md:mx-auto ">
          <ul
            className=" px-2 md:px-2 rounded-md grid grid-cols-5 md:max-w-xl mx-auto  mb-0 list-none flex-wrap md:py-2 py-2 flex-row"
            role="tablist"
          >
            {stepArray.map(({ id, step_name }) => (
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "hover:text-[#353535] text-[0.7rem] md:text-[1rem]  font-bold uppercase shadow-lg  leading-normal  w-[2.6rem] h-[2.6rem] md:w-[4.5rem] md:h-[4.5rem] grid place-items-center rounded-full " +
                    (openTab === id
                      ? "text-white bg-[#F97316]"
                      : "bg-white text-[#353535]")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(id);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  {step_name}
                </a>
              </li>
            ))}
          </ul>
          <br />
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full  mb-7 shadow-lg rounded">
            <div className=" p-6 flex-auto">
              <div className="tab-content tab-space">
                {/* Step1 */}
                <div
                  className={`${
                    openTab === 1 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  <p className="text-sm md:text-xl mx-auto my-2 text-orange-700">
                    အဆင့်(၁) Bitcoin Transaction Hash Code အား 64bit code
                    ပြောင်းလဲခြင်း
                  </p>
                  <p className="text-[#353535] text-md text-justify">
                    Bitcoin လုပ်ငန်းစဉ် နံပါတ်တွေသည် SHA256 Asymmetric
                    Encryption Algorithm နဲ့ ထုတ်လိုက်တဲ့ Hash Code တွေဖြစ်လို့
                    ဘယ်သူမှကြိုတင်မသိနိုင်ပါ
                  </p>

                  <div className="my-3 ">
                    <Formik
                      initialValues={{ convertvalue1: "" }}
                      validate={(values) => {
                        const errors = {};
                        if (!values.convertvalue1) {
                          errors.convertvalue1 = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        const data = {
                          value: values.convertvalue1,
                          type: "HASH_HEX",
                        };

                        convert1(data);
                        setOpenTab(2);

                        setTimeout(() => {
                          setAfter(1);
                        }, 5000);

                        setAfter(0);
                      }}
                    >
                      {(props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                        } = props;
                        return (
                          <Form>
                            <input
                              class={`${
                                errors.convertvalue1
                                  ? "border-2 border-red-600 "
                                  : "border-cyan-600 border-2"
                              }  shadow appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                              id="username"
                              name="convertvalue1"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.convertvalue1}
                            />
                            <span className="text-xs text-red-600">
                              {errors.convertvalue1 &&
                                touched.convertvalue1 &&
                                errors.convertvalue1}
                            </span>
                            <center>
                              <Button
                                variant="contained"
                                size="medium"
                                className="mt-4"
                                type="submit"
                                style={{ outline: "none" }}
                              >
                                Convert
                              </Button>
                            </center>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>

                {/* Step2 */}

                <div
                  className={`${
                    openTab === 2 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  <p className="text-sm md:text-xl my-2 mx-auto text-orange-700">
                    အဆင့် (၂) 64bit တန်ဖိုးအား (16) လုံးပြောင်းလဲခြင်း
                  </p>
                  <p className="text-[#353535] text-md text-justify ">
                    အဆင့်(၁) မှာရရှိလာတဲ့ code နံပါတ်တွေကို 64bit hash
                    တန်ဖိုးတွေဖြစ်အောင် ထပ်တွက်ထုတ်လိုက်ပါတယ်။
                  </p>

                  <div className="my-3 p-2">
                    <Formik
                      initialValues={{
                        convertvalue2: convert1_result?.result,
                      }}
                      enableReinitialize
                      validate={(values) => {
                        const errors = {};
                        if (!values.convertvalue2) {
                          errors.convertvalue2 = "Required";
                        }
                        return errors;
                      }}
                      onSubmit={(values, { setSubmitting }) => {
                        const data = {
                          value: values.convertvalue2,
                          type: "HEX_SLICED",
                        };

                        convert3(data);
                        setOpenTab(3);
                      }}
                    >
                      {(props) => {
                        const {
                          values,
                          errors,
                          touched,
                          handleChange,
                          handleBlur,
                        } = props;

                        return (
                          <Form>
                            <input
                              class={`${
                                errors.convertvalue2
                                  ? "border-2 border-red-600 "
                                  : "border-cyan-600 border-2"
                              }  shadow appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                              id="username"
                              name="convertvalue2"
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.convertvalue2}
                            />
                            <span className="text-xs text-red-600">
                              {errors.convertvalue2 &&
                                touched.convertvalue2 &&
                                errors.convertvalue2}
                            </span>
                            <center>
                              <Button
                                variant="contained"
                                size="medium"
                                className="mt-4"
                                type="submit"
                                style={{ outline: "none" }}
                              >
                                Convert
                              </Button>
                            </center>
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>

                {/* Step3 */}
                <div
                  className={`${
                    openTab === 3 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  <div
                    className={`${
                      openTab === 3 ? "block" : "hidden"
                    } grid grid-cols-1`}
                    id="link2"
                  >
                    <p className="text-sm md:text-xl my-2 mx-auto text-orange-700">
                      အဆင့် (၃) ကိန်းဂဏာန်းပြောင်းလဲခြင်း
                    </p>
                    <p className="text-[#353535] text-md text-justify ">
                      အဆင့်(၂) မှာရရှိလာတဲ့ 64bit hash တန်ဖိုး ရဲ့ ပထမဆုံး 16
                      လုံး တန်ဖိုးကိုယူပီး Hexadecimal to Decimal ပြောင်းလဲခြင်း
                      Formula နဲ့နံပါတ်တွေအဖြစ်ပြောင်းလဲပါတယ်
                    </p>

                    <div className="my-3 p-2">
                      <Formik
                        initialValues={{
                          convertvalue3: convert3_result?.result,
                        }}
                        enableReinitialize
                        validate={(values) => {
                          const errors = {};
                          if (!values.convertvalue3) {
                            errors.convertvalue3 = "Required";
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          const data = {
                            value: values.convertvalue3,
                            type: "SLICED_DECIMAL",
                          };

                          convert4(data);
                          setOpenTab(4);
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                          } = props;

                          return (
                            <Form>
                              <input
                                class={`${
                                  errors.convertvalue3
                                    ? "border-2 border-red-600 "
                                    : "border-cyan-600 border-2"
                                }  shadow appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                id="username"
                                name="convertvalue3"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.convertvalue3}
                              />
                              <span className="text-xs text-red-600">
                                {errors.convertvalue3 &&
                                  touched.convertvalue3 &&
                                  errors.convertvalue3}
                              </span>
                              <center>
                                <Button
                                  variant="contained"
                                  size="medium"
                                  className="mt-4"
                                  type="submit"
                                  style={{ outline: "none" }}
                                >
                                  Convert
                                </Button>
                              </center>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>

                {/* Step4 */}
                <div
                  className={`${
                    openTab === 4 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  <div
                    className={`${
                      openTab === 4 ? "block" : "hidden"
                    } grid grid-cols-1`}
                    id="link2"
                  >
                    <p className="text-sm md:text-xl my-2 mx-auto text-orange-700">
                      အဆင့် (၄) ကိန်းဂဏာန်းပြောင်းလဲခြင်း
                    </p>
                    <p className="text-[#353535] text-md text-justify ">
                      အဆင့်(၃) မှာရရှိလာတဲ့ Decimal နံပါတ်တွေ ကို ပိုင် (Pi)
                      တန်ဖိုး (3.141592653589793) ရဲ့ အဆ 40 နဲ့ စားပြီး ဒဿမ
                      ဂဏန်း တွေအဖြစ် ထပ်ပြောင်းလဲပါတယ်
                    </p>

                    <div className="my-3 p-2">
                      <Formik
                        initialValues={{
                          convertvalue4: convert4_result?.result,
                        }}
                        enableReinitialize
                        validate={(values) => {
                          const errors = {};
                          if (!values.convertvalue4) {
                            errors.convertvalue4 = "Required";
                          }
                          return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                          const data = {
                            value: values.convertvalue4,
                            type: "DECIMAL_CALVAL",
                          };

                          convert4(data);
                          setOpenTab(5);
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                          } = props;
                          return (
                            <Form>
                              <input
                                class={`${
                                  errors.convertvalue4
                                    ? "border-2 border-red-600 "
                                    : "border-cyan-600 border-2"
                                }  shadow appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                id="username"
                                name="convertvalue4"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.convertvalue4}
                              />
                              <span className="text-xs text-red-600">
                                {errors.convertvalue4 &&
                                  touched.convertvalue4 &&
                                  errors.convertvalue4}
                              </span>
                              <center>
                                <Button
                                  variant="contained"
                                  size="medium"
                                  className="mt-4"
                                  type="submit"
                                  style={{ outline: "none" }}
                                >
                                  Convert
                                </Button>
                              </center>
                            </Form>
                          );
                        }}
                      </Formik>
                    </div>
                  </div>
                </div>

                {/* Step5 */}
                <div
                  className={`${
                    openTab === 5 ? "block" : "hidden"
                  } grid grid-cols-1`}
                  id="link2"
                >
                  <div
                    className={`${
                      openTab === 5 ? "block" : "hidden"
                    } grid grid-cols-1`}
                    id="link2"
                  >
                    <div
                      className={`${
                        openTab === 5 ? "block" : "hidden"
                      } grid grid-cols-1`}
                      id="link2"
                    >
                      <p className="text-sm md:text-xl my-2 mx-auto text-orange-700">
                        အဆင့် (၅) ပေါက်ဂဏန်းထွက်ရှိခြင်း
                      </p>
                      <p className="text-[#353535] text-md text-justify">
                        ရရှိလာသောဒဿမ ဂဏန်းများ၏
                        ဒဿမနောက်(၆)လုံးမြောက်နှင့်(၇)လုံးမြောက် နံပါတ်များအား
                        ပေါက်ဂဏန်းအဖြစ် ထွက်ရှိပါသည်။
                      </p>
                      <div className="my-3 ">
                        <Formik
                          initialValues={{
                            convertvalue5: convert4_result?.result,
                          }}
                          enableReinitialize
                          validate={(values) => {
                            const errors = {};
                            if (!values.convertvalue5) {
                              errors.convertvalue5 = "Required";
                            }
                            return errors;
                          }}
                          onSubmit={(values, { setSubmitting }) => {
                            const data = {
                              value: values.convertvalue5,
                              type: "CALVAL_ODDS",
                            };

                            convert5(data);
                          }}
                        >
                          {(props) => {
                            const {
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                            } = props;
                            return (
                              <Form>
                                <input
                                  class={`${
                                    errors.convertvalue5
                                      ? "border-2 border-red-600 "
                                      : "border-cyan-600 border-2"
                                  }  shadow appearance-none  rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                                  id="username"
                                  name="convertvalue5"
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.convertvalue5}
                                />
                                <span className="text-xs text-red-600">
                                  {errors.convertvalue5 &&
                                    touched.convertvalue5 &&
                                    errors.convertvalue5}
                                </span>
                                <center>
                                  <Button
                                    variant="contained"
                                    size="medium"
                                    className="mt-4"
                                    type="submit"
                                    style={{ outline: "none" }}
                                  >
                                    Convert
                                  </Button>
                                </center>
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                      <span className="text-[#353535] text-xl text-center mb-12 flex flex-col justify-center items-center gap-y-7 w-[12rem] h-[12rem] rounded-full bg-white border border-black mx-auto mt-4">
                        <p className="text-[#353535]">ပေါက်ဂဏန်း</p>
                        <div className="h-[0.5px] w-10 bg-[#808080]"></div>

                        <span className="text-[60px] text-[#BA0202]">
                          {" "}
                          {convert5_result?.result
                            ? convert5_result?.result
                            : "XX"}
                        </span>
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function TabsRender(props) {
  const {
    historyAll,
    allhistory,
    checkData,
    convert1,
    convert1_result,
    convert2,
    convert2_result,
    convert3,
    convert3_result,
    convert4,
    convert4_result,
    convert5,
    convert5_result,

    result1201,
    result430,
    resultDate,
  } = props;
  const path = useLocation();

  useEffect(() => {
    if (path.pathname === "/history") {
    }
  }, []);

  return (
    <>
      {path.pathname === "/history" ? (
        <Tabs1 color="cyan" allhistory={allhistory} />
      ) : path.pathname === "/proof" ? (
        <Tabs3
          color="yellow"
          checkData={checkData}
          convert1={convert1}
          convert1_result={convert1_result}
          convert2={convert2}
          convert2_result={convert2_result}
          convert3={convert3}
          convert3_result={convert3_result}
          convert4={convert4}
          convert4_result={convert4_result}
          convert5={convert5}
          convert5_result={convert5_result}
        />
      ) : (
        <Tabs2
          color="yellow"
          result1201={result1201}
          result430={result430}
          resultDate={resultDate}
        />
      )}
    </>
  );
}

const mapDispatchToProps = {
  historyAll,
  convert1,
  convert2,
  convert3,
  convert4,
  convert5,
};
const mapStateToProps = (state) => ({
  allhistory: state.get_history_all?.get_history_all,
  convert1_result: state.get_convert_result?.get_convert1_result,
  convert2_result: state.get_convert_result?.get_convert2_result,
  convert3_result: state.get_convert_result?.get_convert3_result,
  convert4_result: state.get_convert_result?.get_convert4_result,
  convert5_result: state.get_convert_result?.get_convert5_result,
});
export default connect(mapStateToProps, mapDispatchToProps)(TabsRender);
