import axios from "axios";
import {
  GET_TRANS_ALL,
  GET_LAST_30,
  GET_LAST_120,
  GET_LAST_300,
} from "./types";

import { endpoint, prodEndpoint } from "../config";

const URL = process.env.NODE_ENV === "development" ? endpoint : prodEndpoint;

const Alltrans = () => {
  // console.log(data);

  return async (dispatch) => {
    try {
      await axios
        .get(`${URL}api/v1/tx/btc?date_type=120&tx_type=BTC_120`)
        .then((response) => {
          // console.log("All Transactions", response.data);
          dispatch({
            type: GET_TRANS_ALL,
            payload: response.data,
          });
        });
    } catch (error) {
      // console.log("Iam error");
      // console.log(error);
    }
  };
};

const getLastTx30 = (tx_type) => {
  // console.log(tx_type);
  return async (dispatch) => {
    // console.log("tx_type", tx_type);
    try {
      await axios
        .get(`${URL}api/v1/tx/last?tx_type=${tx_type}`)
        .then((response) => {
          // console.log("Last Transactions", response.data);
          dispatch({
            type: GET_LAST_30,
            payload: response.data,
          });
        });
    } catch (error) {
      // console.log("Iam error");
      // console.log(error);
    }
  };
};
const getLastTx120 = (tx_type) => {
  // console.log(tx_type);
  return async (dispatch) => {
    // console.log("tx_type", tx_type);
    try {
      await axios
        .get(`${URL}api/v1/tx/last?tx_type=${tx_type}`)
        .then((response) => {
          // console.log("Last Transactions", response.data);
          dispatch({
            type: GET_LAST_120,
            payload: response.data,
          });
        });
    } catch (error) {
      // console.log("Iam error");
      // console.log(error);
    }
  };
};

const getLastTx300 = (tx_type) => {
  // console.log(tx_type);
  return async (dispatch) => {
    // console.log("tx_type", tx_type);
    try {
      await axios
        .get(`${URL}api/v1/tx/last?tx_type=${tx_type}`)
        .then((response) => {
          // console.log("Last Transactions", response.data);
          dispatch({
            type: GET_LAST_300,
            payload: response.data,
          });
        });
    } catch (error) {
      // console.log("Iam error");
      // console.log(error);
    }
  };
};

const getLastTx = async ({ tx_type, date_type }) => {
  // console.log(data);
  try {
    let end_point = `${URL}api/v1/tx/last?tx_type=${tx_type}`;
    if (date_type) {
      end_point = `${URL}api/v1/tx/last?tx_type=${tx_type}&date_type=${date_type}`;
    }
    const result = await axios.get(end_point);

    return result.data?.result?.result;
  } catch (error) {
    // console.log("getLastTx error");
    // console.log(error);
  }
};

export { Alltrans, getLastTx30, getLastTx120, getLastTx300, getLastTx };
