import { combineReducers } from "redux";

import { responsiveStateReducer } from "redux-responsive";

import { connectRouter } from "connected-react-router";
import trans1Reducer from "./trans1Reducer";
import historyReducer from "./historyReducer";
import convertReducer from "./convertReducer";

export const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    get_trans_all: trans1Reducer,
    get_last_all: trans1Reducer,
    get_history_all: historyReducer,
    get_convert_result: convertReducer,
    browser: responsiveStateReducer,
  });
