import React from "react";
import MasterUi from "../master-ui/MasterUi";
import svg1 from "../../assets/images/bitcoin/arrow.svg";
import TabsRender from "../../reusable/tabs/TabsRender";
import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";

const Proof = (props) => {
  const { alltrans } = props;
  const checkData = alltrans?.checkData;
  return (
    <MasterUi>
      <TabsRender checkData={checkData} />
      {/* <div className="min-h-screen   py-6 flex flex-col justify-center sm:py-12">
        <div className="py-3 sm:max-w-6xl sm:mx-auto w-full px-2 sm:px-0">
          <div className="relative text-gray-700 antialiased text-sm font-semibold">
            <div className="hidden sm:block w-1 bg-blue-300 absolute h-full left-1/2 transform -translate-x-1/2"></div>

            <div className="mt-7 sm:mt-0 sm:mb-12 ">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div className="p-2 bg-white rounded shadow w-full break-all">
                      <h3 className="text-xl">
                        Step 1: Retrieve the transaction hash as lottery seed
                      </h3>
                      <span>
                        The lottery seed X is retrieve from the latest
                        unconfirmed transaction hash in bitcoin network, which
                        is purely random and unpredictable.{" "}
                      </span>
                      <br />
                      <p>( {checkData?.transaction} )</p>
                      <br />
                      <div className="flex">
                        <TextField
                          label="fullWidth"
                          id="fullWidth"
                          inputProps={{
                            style: { fontSize: 15, height: ".5rem" },
                          }}
                        />
                        <Button variant="contained" size="small">
                          Convert
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <img src={svg1} alt="dsfsd" />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-end w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pl-8">
                    <div className="p-2 bg-white rounded shadow w-full break-all">
                      <h3 className="text-xl">
                        Step 2: HASH SHA256 encryption conversion
                      </h3>
                      <span>
                        X is transformed by the HASH SHA256 asymmetric
                        encryption algorithm, which can be converted into a new
                        hex 64-bit hash value Y
                      </span>

                      <p>( {checkData?.hex} )</p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <img src={svg1} alt="dsfsd" />
                </div>
              </div>{" "}
            </div>

            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div className="p-2 bg-white rounded shadow w-full break-all">
                      <h3 className="text-xl">
                        Step 3.1: Mathematical conversion
                      </h3>
                      <span>
                        Take the first 16 digits of the 64-bit hash value Y.
                      </span>

                      <p>( {checkData?.slicedHex} )</p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <img src={svg1} alt="dsfsd" />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div className="p-2 bg-white rounded shadow w-full break-all">
                      <h3 className="text-xl">
                        Step 3-2: Mathematical conversion
                      </h3>
                      <span>
                        Obtaining a decimal number Z through the decimal
                        conversion formula.
                      </span>

                      <p>( {checkData?.decimalHex} )</p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <img src={svg1} alt="dsfsd" />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-start w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pr-8">
                    <div className="p-2 bg-white rounded shadow w-full break-all">
                      <h3 className="text-xl">
                        Step 3-3: Mathematical conversion
                      </h3>
                      <span>
                        Divide Z by the 蟺 (3.14159265358979) to the power of 40
                        to get a new number A with decimal point.
                      </span>

                      <p>( {checkData?.calVal} )</p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <img src={svg1} alt="dsfsd" />
                </div>
              </div>
            </div>

            <div className="mt-6 sm:mt-0 sm:mb-12">
              <div className="flex flex-col sm:flex-row items-center">
                <div className="flex justify-end w-full mx-auto items-center">
                  <div className="w-full sm:w-1/2 sm:pl-8">
                    <div className="p-2 bg-white rounded shadow">
                      <h3 className="text-xl">
                        Step 4: Generate final draw number
                      </h3>
                      <span>
                        The 6th to 7th digits after the decimal point of A are
                        taken as the final draw number.
                      </span>
                      <br />
                      <br />
                      <p>{checkData?.odds}</p>
                    </div>
                  </div>
                </div>
                <div className="rounded-full bg-blue-500 border-white border-4 w-8 h-8 absolute left-1/2 -translate-y-4 sm:translate-y-0 transform -translate-x-1/2 flex items-center justify-center">
                  <img src={svg1} alt="dsfsd" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <TabsRender /> */}
    </MasterUi>
  );
};

export default Proof;
