import React, { useState } from "react";
import "./CountDown.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CountDown = (props) => {
  const { hours, minutes, seconds } = props;

  let hourPercent = (hours / 24) * 100;
  let minutePercent = minutes === "00" ? 0 : ((60 - minutes) / 60) * 100;
  let secondPercent = seconds === "00" ? 0 : ((60 - seconds) / 60) * 100;

  let hmsArray = [
    {
      id: 1,
      text1: hours,
      text2: "HOUR",
      percent: hourPercent,
    },
    {
      id: 2,
      text1: minutes,
      text2: "MINUTE",
      percent: minutePercent,
    },
    {
      id: 3,
      text1: seconds,
      text2: "SECOND",
      percent: secondPercent,
    },
  ];
  return (
    <section class="slider_section grid grid-cols-3 gap-x-10">
      {hmsArray.map(({ id, text1, text2, percent }) => (
        <div key={id}>
          <div className="w-[3.75rem] h-[3.75rem mb-3">
            <CircularProgressbar
              value={percent}
              text={`${text1}`}
              strokeWidth={5}
              styles={buildStyles({
                pathColor: "#FB9148",
                trailColor: "#eee",
                textSize: "26px",
                textColor: "black",
              })}
            />
          </div>
          <p className="text-[12px] text-[#B4B2B0]">{text2}</p>
        </div>
      ))}

      {/* <div class="container ">
        <div class="detail-box">
          <div class="box_container">
            <div class="box">
              <div class="num-box">
                <span id="countHour" style={{ color: "#fff" }}>
                  {hours.toString().length === 1 ? `0${hours}` : hours}
                </span>
              </div>
              <h5>Hr</h5>
            </div>
            <div class="box">
              <div class="num-box">
                <span id="countMin" style={{ color: "#fff" }}>
                  {minutes.toString().length === 1 ? `0${minutes}` : minutes}
                </span>
              </div>
              <h5>Min</h5>
            </div>
            <div class="box">
              <div class="num-box">
                <span id="countSec" style={{ color: "#fff" }}>
                  {seconds.toString().length === 1 ? `0${seconds}` : seconds}
                </span>
              </div>
              <h5>Sec</h5>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default CountDown;
